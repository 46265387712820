import React, { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import PheonixCardProps from "../interfaces/PheonixCardProps";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const PheonixCard: React.FC<PheonixCardProps> = ({
  title,
  IconText,
  buttonText,
  buttonDisabled,
  buttonText1,
  buttonDisabled1,
  onClickButton,
  children,
  buttonText2,
  handleCancelbutton,
  isExpanded = false,
  toggleExpand,
}) => {
  const [isContentvisible, setIsContentVisible] = useState(true);

  const toggleContentVisibility = () => {
    setIsContentVisible(!isContentvisible);
  };

  return (
    <div>
      <Card
        style={{
          borderRadius: "7.5px",
          width: "calc(100% - 20px)",
          marginBottom: "20px",
          color: "#034342",
        }}
      >
        <CardContent style={{ display: isContentvisible ? "block" : "none" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              style={{
                fontFamily: "Roboto",
                fontWeight: 600,
                marginBottom: "-0.5rem",
                marginTop: "10px",
                marginRight: "2%",
              }}
            >
              {title}
            </Typography>
            {buttonText && (
              <Button
                disabled={buttonDisabled}
                variant="contained"
                color="primary"
                size="small"
                onClick={onClickButton}
                style={{
                  backgroundColor: "#032C43",
                  color: "white",
                }}
              >
                {buttonText}
              </Button>
            )}
          </div>
          {children}
          {buttonText1 && (
            <div>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#165D59",
                  color: "#FFFFFF",
                  marginRight: "1rem",
                  padding: "6px, 16px, 6px, 16px",
                }}
                size="small"
                onClick={handleCancelbutton}
              >
                {buttonText2}
              </Button>
              <Button
                disabled
                variant="contained"
                size="small"
                onClick={onClickButton}
                style={{
                  backgroundColor: "#000000",
                  opacity: "20%",
                  padding: "6px 16px",
                  color: "white",
                  marginRight: "1rem",
                }}
              >
                {buttonText1}
              </Button>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default PheonixCard;
