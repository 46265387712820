import digitalicon from "../assets/digitalicon.png";
import techicon from "../assets/techicon.png";
import scmicon from "../assets/scmicon.png";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import webdevelopmenticon from "../assets/webdevelopicon.png";
import CloudSyncOutlinedIcon from "@mui/icons-material/CloudSyncOutlined";
import webhosticon from "../assets/webhosticon.png";
import erp from "../assets/erp.png";
export const firstBoxMockData = [
  {
    image: webhosticon,
    title: "Web Hosting",
    planType: "Premium Plan",
    date: "Validity: 10 Dec 2024",
    bulletPoints: [
      "The world's largest free online resource on UX Design.",
      "The new articles published every day.",
      "Free online resource on UX Design",
      "Largest free online resource every day",
    ],
    buttonText: "View Payment Details",
  },
  {
    image: webdevelopmenticon,
    title: "Web Development",
    planType: "Basic Plan",
    date: "Validity: 10 Dec 2024",
    bulletPoints: [
      "The world's largest free online resource on UX Design.",
      "The new articles published every day.",
      "Free online resource on UX Design",
      "Largest free online resource every day",
    ],
    buttonText: "View Payment Details",
  },
];

export const secondBoxMockData = [
  { title: "Web Maintenance", image: webhosticon },
  { title: "ERP", image: erp },
  { title: "Digital Marketing", image: digitalicon },
  { title: "SCM", image: scmicon },
  { title: "Tech Support", image: techicon },
];

export const productsData = [
  { title: "Web Hosting", image: webhosticon, text: "View Details" },
  { title: "Dev-ops", image: erp, text: "View Details" },
];
export const productsFullData = [
  { title: "Web Maintenance", image: webhosticon, text: "View Details" },
  { title: "Dev-ops", image: erp, text: "View Details" },
  { title: "Web Applications", image: digitalicon, text: "View Details" },
  { title: "Web Design", image: scmicon, text: "View Details" },
  { title: "Mobile App", image: techicon, text: "View Details" },
];

export const items = [
  {
    name: "Web Maintenance",
    image: webhosticon,
    text: "View Details",
  },
  {
    name: "Dev-ops",
    image: erp,
    text: "View Details",
  },
  {
    name: "Web Applications",
    image: digitalicon,
    text: "View Details",
  },
  {
    name: "Web Design",
    image: scmicon,
    text: "View Details",
  },
  {
    name: "Mobile App",
    image: techicon,
    text: "View Details",
  },
];
