import React from 'react';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import PheonixSnackBarProps from '../interfaces/PheonixSnackBarProps';

const PheonixSnackBar: React.FC<PheonixSnackBarProps> = ({ open, onClose, message, vertical, horizontal,customAction  }) => {
  const anchorOrigin: SnackbarOrigin = { vertical, horizontal };
return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      open={open}
      onClose={onClose}
      message={message}
      action={customAction} 
    />
  );
};
export default PheonixSnackBar;