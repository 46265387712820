import React from "react";
import { Card , CardContent, Box, Typography, Button, SxProps, Theme } from '@mui/material';
import CardMedia from "@mui/material/CardMedia";
import PheonixButton from '../Components/PheonixButton';
import ServicesCardProps from "../interfaces/ServicesCardProps";

const ServicesCard: React.FC<ServicesCardProps> = ({ image,title,text }) => {
  return (
    <Card sx={{ display: 'flex', width: '160px', height: '130px', borderRadius: '10px',flexDirection:'row',justifyContent:'space-between',alignItems:'center', '&:hover': {
      backgroundColor: '#EBECFF',
    }, }}>
 
    <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center',alignItems:'center' }}>
    <Typography style={{ fontWeight: 600, fontSize: '16px',lineHeight:'19.36px',color:'#181D8C' }}>
                {title}
            </Typography>
        <CardMedia
        component="img"
        style={{ width: '24px', height:'24px',  marginTop:'8%' }}
        image={image}
        alt={title}
    />
     <Typography style={{ fontWeight: 400, fontSize: '10px',lineHeight:'12.1px',color:'#181D8C',marginTop:'5%' }}>
                {text}
            </Typography>
           

    </CardContent>
</Card>
  );
};

export default ServicesCard;