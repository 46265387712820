import React, { ChangeEvent, useEffect, useState } from "react";
import PheonixProductTableProps from "../interfaces/PheonixProductTableprops";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Autocomplete,
  TextField,
  Select,
  MenuItem,
  InputAdornment,
  Button,
} from "@mui/material";

const PheonixProductTable: React.FC<PheonixProductTableProps> = ({
  columns,
  rows,
  view,
  edit,
  handleInputChange,
  handleAddRow,
  handleDeleteRow,
  fieldErrors,
}) => {
  const planOptions = ["Taxable", "No Tax"];
  const newOptions = ["Basic", "Medium", "Premium", "Pro", "Custom"];
  const billOptions = ["Monthly","Quarterly","Half yearly","Yearly"]
  const renderCell = (column: any, row: any, index: any) => {
    if (column.render) {
      return column.render(index);
    }

    if (
      column.field === "Rate" ||
      column.field === "Quantity" ||
      column.field === "Amount" ||
      column.field === "Product Details" ||
      column.field === "HSN Number"
    ) {
      return (
        <TextField
          type="text"
          value={row[column.field] || ""}
          disabled={view && !edit}
          placeholder={
            column.field === "Rate" || column.field === "Amount"
              ? "0.00"
              : column.field === "Quantity"
              ? "0"
              : column.field === "Product Details" ? "Enter Product"
              : "Enter HSN"
          }
          size="small"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleInputChange(index, column.field, e.target.value)
          }
          error={!!fieldErrors?.[index]?.[column.field]}
          helperText={fieldErrors?.[index]?.[column.field] || ""}
          sx={{
            padding: "4px",
            marginLeft:
              column.field === "Product Details" || column.field === "Amount" || column.field === "Tax"
                ? "-14%"
                : "-10%",
            marginRight :  column.field === "Rate" ? "-15px":'0px',
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "none",
              },
            },
          }}
          inputProps={{
            style: {
              textAlign: column.field === "Product Details" ? "left" : "right",
            },
          }}
          InputProps={{
            style: { textAlign: "right" },
          }}
        />
      );
    }

    if (column.field === "Tax"||column.field === "Product Plan" || column.field === "Billing Cycle") {
      return (
        <Autocomplete
          disablePortal
          options={column.field === "Tax" ? planOptions : column.field ==="Product Plan"? newOptions : billOptions}
          size="small"
          disabled={view && !edit}
          renderInput={(params) => <TextField {...params} label="" />}
          onChange={(event, newValue) =>
            handleInputChange(index, column.field, newValue)
          }
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "15px",
            },
          }}
          value={row[column.field] || null}
          style={{ width: "130px", marginLeft: "14%" }}
        />
      );
    }

    return row[column.field];
  };
  return (
    <TableContainer>
    <Table>
      <TableHead>
        <TableRow style={{ fontFamily: 'Roboto' }}>
          {columns.map((column) => (
            <TableCell
              key={column.field}
              style={{
                width:
                  column.field === 'Rate' || column.field === 'HSN Number'
                    ? '120px'
                    : column.field === 'Quantity' || column.field === 'Tax'
                    ? '80px'
                    : column.field === 'Amount'
                    ? '120px'
                    : column.field === 'Billing Cycle' || column.field === 'Product Plan'
                    ? '130px'
                    : column.field === 'Product Details'
                    ? '170px'
                    : '100px',
                padding: '10px',
                fontSize: '16px',
                color: 'black',
                fontFamily: 'Roboto',
                fontWeight: 600,
                  
                textAlign:
                  column.field === 'Product Details'
                    ? 'left'
                    : column.field === 'Tax' ||
                      column.field === 'Billing Cycle' ||
                      column.field === 'Quantity' || column.field === 'Rate'
                    ? 'right'
                    : 'center',
              }}
            >
              {column.headerName}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, index) => (
          <TableRow
            key={index}
            style={{
              color: 'black',
              textAlign: 'center',
              fontFamily: 'Roboto',
              fontSize: '16px',
            }}
          >
            {columns.map((column) => (
              <TableCell
                key={column.field}
                style={{
                  width:
                    column.field === 'Rate' || column.field === 'HSN Number'
                      ? '120px'
                      : column.field === 'Quantity' || column.field === 'Tax'
                      ? '80px'
                      : column.field === 'Amount'
                      ? '120px'
                      : column.field === 'Billing Cycle' || column.field === 'Product Plan'
                      ? '130px'
                      : column.field === 'Product Details'
                      ? '170px'
                      : '100px',
                  fontFamily: 'Roboto',
                  color: 'black',
                  textAlign:
                    column.field === 'Product Details' ? 'left' : 'center',
                }}
              >
                {renderCell(column, row, index)}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
  );
};

export default PheonixProductTable;
