import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Typography,
  FormControl,
  InputLabel,
  Box,
  Button,
  TextField,
  IconButton,
  Autocomplete,
  Card,
  CardContent,
} from "@mui/material";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ERROR_MESSAGES, TEXT_MESSAGES } from "../const";
import PheonixProductTable from "./PheonixProductTable";
import PheonixTextField from "../Components/PheonixTextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import dayjs from "dayjs";
import InvoiceTableProps from "../interfaces/InvoiceTableProps";
import InvoiceData from "../interfaces/PheonixInvoice";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const InvoiceTable: React.FC<InvoiceTableProps> = ({
  InvoiceData1,
  setInvoiceData1,
  status1,
  setStatus1,
  fieldErrors,
  setFieldErrors,
  customerOptions,
  setCustomerOptions,
  rows,
  setRows,
  tableFieldErrors,
  setTableFieldErrors
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const editQuote = location.state?.editQuote || null;
  const [totalAmount, setTotalAmount] = useState(null);
  const [loggedInUser, setLoggedInUser] = useState<string>("");

  useEffect(() => {
    const usertype = localStorage.getItem("usertype");
    if (usertype !== null) {
      setLoggedInUser(usertype);
    }
  }, []);

  const calculateTotalAmount = (rows: any) => {
    return rows.reduce(
      (total: any, row: any) => total + parseFloat(row.Amount || 0),
      0
    );
  };

  useEffect(() => {
    const totalAmount = calculateTotalAmount(rows);
    setTotalAmount(totalAmount);
    setInvoiceData1((prevState) => ({
      ...prevState,
      ["Total Amount"]: totalAmount,
    }));
  }, [rows]);
  const handleChange = (event: SelectChangeEvent) => {
    setStatus1(event.target.value);
  };
  const handleQuotationDateChange = (date: any) => {
    const formattedDate = dayjs(date).format("DD/MM/YYYY");
    setInvoiceData1((prevState) => ({
      ...prevState,
      ["Invoice Date"]: formattedDate,
    }));
  };
  const handleExpiryDateChange = (date: any) => {
    const formattedDate = dayjs(date).format("DD/MM/YYYY");
    setInvoiceData1((prevState) => ({
      ...prevState,
      ["Expiry Date"]: formattedDate,
    }));
  };
  const handleDueDateChange = (date: any) => {
    const formattedDate = dayjs(date).format("DD/MM/YYYY");
    setInvoiceData1((prevState) => ({
      ...prevState,
      ["Due Date"]: formattedDate,
    }));
  };
  const handleNameChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    const { id, name,email,number,gst_no,address }  = newValue;
    setInvoiceData1((prevQuoteData) => ({
      ...prevQuoteData,
      "Customer Name": name,
      "Customer Id": id,
      "Email":email,
      "Phone Number":number,
    }));
  };
  const planOptions = ["IGST", "CGST + IGST"];
  const handleGstChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setInvoiceData1((prevData) => ({
      ...prevData,
        "GST Type":newValue
    }));
  };

  const handleDeleteRow = (index: number) => {
    if (rows.length > 1) {
      const newRows = [...rows];
      newRows.splice(index, 1);
      setRows(newRows);
      setTableFieldErrors((prevErrors: { [key: string]: any }) => {
        const newErrors = { ...prevErrors };
        if (newErrors[index]) {
          Object.keys(newErrors[index]).forEach((field) => {
            newErrors[index][field] = "";
          });
        }
        return newErrors;
      });
    }
  };
  const handleAddRow = () => {
    const newRow = {
      "Product Details": "",
      "Quantity": "",
      "Rate": "",
      "Tax": "",
      "Amount": "",
    };
    setRows([...rows, newRow]);
  };
  const handleInputChange = (label: string, value: string) => {
    const newFormData: InvoiceData = { ...InvoiceData1, [label]: value };
    switch (label) {
      case "Customer Name":
        if (!/^[a-zA-Z\s]*$/.test(value)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.NAME_ERROR,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
        }
        break;
        case "Customer Notes":
        if (!value) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.DESC_ERROR,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
        }
        break;
      case "GST Number":
        if (!value) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.GST_ERROR,
          }));
        } else if (!/^[a-zA-Z0-9]+$/.test(value)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.GST_ERR1,
          }));
        } else if (value.length !== 15) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.GST_LENGTH,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
        }
        break;
      case "SGST":
        if (!value) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.GST_ERROR,
          }));
        } else if (!/^\d*\.?\d*$/.test(value)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.AMOUNT_ERROR,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
        }
        break;
      case "IGST":
        if (!value) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.GST_ERROR,
          }));
        } else if (!/^\d*\.?\d*$/.test(value)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.AMOUNT_ERROR,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
        }
        break;

      case "Product/Service":
        if (!value) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.PROD_ERROR,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
        }
        break;
      case "Status":
        if (!value) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.PROD_ERROR,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
        }
        break;
      case "Phone Number":
        if (!/^[0-9]*$/.test(value)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.NUMBER_ERROR,
          }));
        } else if (value.length !== 10) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.NUMBER_LENGTH,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
        }
        break;
      case "Total Amount":
        if (!value) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.AMT_ERR,
          }));
        } else if (!/^\d*\.?\d*$/.test(value)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.AMOUNT_ERROR,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
        }
        break;
      case "Paid Amount":
        if (!value) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.AMT_ERR,
          }));
        } else if (!/^\d*\.?\d*$/.test(value)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.AMOUNT_ERROR,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
        }
        break;
      case "Email":
        if (!TEXT_MESSAGES.EMAIL_REG.test(value)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.MAIL_ERROR,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
        }
        break;
      default:
        return true;
    }
    setInvoiceData1(newFormData);
  };

  const handleInputChange1 = (
    index: number,
    fieldName: keyof (typeof rows)[0],
    value: string
  ) => {
    if (fieldName === "Product Details") {
     if (/[^\w\s]/.test(value)) {
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: ERROR_MESSAGES.BATCH_VAL,
          },
        }));
      } else {
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: "",
          },
        }));
      }
    } else if (fieldName === "Rate") {
      const isValidNumber = /^\d*.?\d+$/.test(value) && parseFloat(value) >= 0;
      if (!isValidNumber) {
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: ERROR_MESSAGES.MRP_VAL,
          },
        }));
      } else {
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: "",
          },
        }));
      }
    } else if (fieldName === "Quantity") {
      if (!/^\d+$/.test(value)) {
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: ERROR_MESSAGES.QUANTITY_VAL,
          },
        }));
      } else if (!value) {
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: ERROR_MESSAGES.QUANTITY_REQ,
          },
        }));
      } else {
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: "",
          },
        }));
      }
    } else if (fieldName === "Amount") {
      const isValidNumber = /^\d*.?\d+$/.test(value) && parseFloat(value) >= 0;
      if (!isValidNumber) {
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: ERROR_MESSAGES.AMT_VAL,
          },
        }));
      } else if (!value) {
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: ERROR_MESSAGES.AMT_REQ,
          },
        }));
      } else {
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: "",
          },
        }));
      }
    }

    const newRows = rows.map((row, rowIndex) => {
      if (index === rowIndex) {
        return { ...row, [fieldName]: value };
      }
      return row;
    });
    if (fieldName === "Rate" || fieldName === "Quantity") {
      const rate = parseFloat(newRows[index].Rate || "0");
      const quantity = parseFloat(newRows[index].Quantity || "0");
      const amount = (rate * quantity).toFixed(2); 
      newRows[index].Amount = amount.toString();
    }
    setRows(newRows);
  };


  const columns = [
    
    {
      field: "Product Details",
      headerName: "Product Details",
    },
    {
      field: "Quantity",
      headerName: "Quantity",
    },

    {
      field: "Rate",
      headerName: "Rate",
    },
    {
      field: "Tax",
      headerName: "Tax",
    },

    {
      field: "Amount",
      headerName: "Amount",
    },
    {
      field: "Action",
      headerName: "Action",
      render: (index: number) => (
        <div style={{ display: "flex", alignItems: "center" }}>
           <IconButton
            onClick={() => handleAddRow()}
            style={{ color: "#181D8C" }}
          >
            <AddCircleOutlineOutlinedIcon />
          </IconButton>
          <IconButton
            onClick={() => handleDeleteRow(index)}
            style={{ color: "#D32F2F" }}
          >
            <CloseOutlinedIcon />
          </IconButton>
        </div>
      ),
    },
  ];
  return (
    <Box>
      <table style={{ width: "100%" }}>
        <tbody>
          <tr>
            <td
              style={{
                display: "flex",
                padding: "10px",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Invoice Date"
                  format="DD/MM/YYYY"
                  value={
                    InvoiceData1["Invoice Date"]
                      ? dayjs(InvoiceData1["Invoice Date"], "DD/MM/YYYY")
                      : null
                  }
                  onChange={handleQuotationDateChange}
                  disablePast
                  sx={{
                    width: 309,
                  }}
                />
              </LocalizationProvider>
              <div style={{marginLeft:'30px'}}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Expiry Date"
                  format="DD/MM/YYYY"
                  value={
                    InvoiceData1["Expiry Date"]
                      ? dayjs(InvoiceData1["Expiry Date"], "DD/MM/YYYY")
                      : null
                  }
                  onChange={handleExpiryDateChange}
                  disablePast
                  minDate={InvoiceData1["Invoice Date"]
                    ? dayjs(InvoiceData1["Invoice Date"], "DD/MM/YYYY")
                    :  dayjs()}
                  sx={{
                    width: 309,
                  }}
                />
              </LocalizationProvider>
              </div>
            </td>
            <td style={{ width: "25%", padding: "10px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Due Date"
                  format="DD/MM/YYYY"
                  value={
                    InvoiceData1["Due Date"]
                      ? dayjs(InvoiceData1["Due Date"], "DD/MM/YYYY")
                      : null
                  }
                  minDate={InvoiceData1["Invoice Date"]
                    ? dayjs(InvoiceData1["Invoice Date"], "DD/MM/YYYY")
                    :  dayjs()}
                    maxDate={
                      InvoiceData1["Expiry Date"]
                        ? dayjs(InvoiceData1["Expiry Date"], "DD/MM/YYYY")
                        : null
                    }
                  onChange={handleDueDateChange}
                  disablePast
                  sx={{
                    width: 309,
                  }}
                />
              </LocalizationProvider>
            </td>
            <td style={{ width: "25%" }}></td>
          </tr>
          <tr>
            <td colSpan={4}>
            <div style={{marginLeft:'8px'}}>
                <Card>
                  <CardContent style={{backgroundColor:'lightgrey',height:'3px',}}>
                    <div style={{marginTop:'-10px'}}>
                    Products
                    </div>
                  </CardContent>
                  <div>
                    <PheonixProductTable 
                      rows={rows}
                      columns={columns}
                      handleInputChange={handleInputChange1}
                      handleAddRow={handleAddRow}
                      handleDeleteRow={handleDeleteRow}
                      fieldErrors={tableFieldErrors}
                    />
                  </div>
                </Card>
            </div>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
            <PheonixTextField
                id="outlined-error"
                type="textarea"
                value={InvoiceData1["Customer Notes"]}
                placeholder="Customer Notes"
                name="Customer Notes"
                label="Customer Notes"
                variant="outlined"
                size="small"
                error={!!fieldErrors?.["Customer Notes"]}
                helperText={fieldErrors["Customer Notes"]}
                onChange={(e) =>
                  handleInputChange(e.target.name, e.target.value)
                }
                style={{ width: "652px" }}
                multiline={true}
                rows={2}
              />
            </td>
            <td style={{ width: "25%" }}></td>
            <td style={{ width: "25%" }}></td>
            <td style={{ width: "25%" }}></td>
          </tr>
          <tr>
            <td
              style={{
                display: "flex",
                padding: "10px",
              }}
            >
              <PheonixTextField
                id="outlined-error"
                type="text"
                placeholder="Total Amount"
                name="Total Amount"
                label="Total Amount"
                variant="outlined"
                size="small"
                value={InvoiceData1["Total Amount"]}
                onChange={(e) =>
                  handleInputChange(e.target.name, e.target.value)
                }
                error={!!fieldErrors?.["Total Amount"]}
                helperText={fieldErrors["Total Amount"]}
                style={{ width: "309px" }}
              />
              <div style={{marginLeft:'30px'}}>
            <PheonixTextField
                id="outlined-error"
                type="text"
                placeholder="Paid Amount"
                label="Paid Amount"
                variant="outlined"
                size="small"
                name="Paid Amount"
                value={InvoiceData1["Paid Amount"]}
                onChange={(e) =>
                  handleInputChange(e.target.name, e.target.value)
                }
                error={!!fieldErrors?.["Paid Amount"]}
                helperText={fieldErrors["Paid Amount"]}
                style={{ width: "309px" }}
              />
            </div>
            </td>
            
            <td style={{ width: "25%" }}></td>
          </tr>

          <tr>
            <td
              style={{
                display: "flex",
                padding: "10px",
              }}
            >
              <FormControl sx={{ width: "309px" }}>
                <InputLabel id="demo-select-small-label">Status</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  label="Status"
                  value={status1}
                  onChange={handleChange}
                >
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  <MenuItem value={"Paid"}>Paid</MenuItem>
                  <MenuItem value={"Unpaid"}>Unpaid</MenuItem>
                  <MenuItem value={"Partially paid"}>Partially paid</MenuItem>
                  <MenuItem value={"Cancelled"}>Cancelled</MenuItem>
                  <MenuItem value={"Overdue"}>Overdue</MenuItem>
                </Select>
              </FormControl>
              <div style={{marginLeft:'30px'}}>
              <Autocomplete
                disablePortal
                options={planOptions}
                renderInput={(params) => (
                  <TextField {...params} label="GST Type" />
                )}
                onChange={(event, newValue) =>
                  handleGstChange(event, newValue)
                }
                value={InvoiceData1["GST Type"]}
                style={{ width: "309px" }}
              />
              </div>
            </td>
            <td style={{ width: "25%" }}></td>
            <td style={{ width: "2%", marginRight: "5%" }}></td>
          </tr>
          <tr>
            <td
              style={{
                display: "flex",
                padding: "10px",
              }}
            >
              <Autocomplete
                disablePortal
                options={customerOptions}
                renderInput={(params) => (
                  <TextField {...params} label="Customer Name" />
                )}
                getOptionLabel={(option) => option.customerNo ? `${option.name} (${option.customerNo})` : option.name}
                disableClearable={true}
                onChange={(event, newValue) =>
                  handleNameChange(event, newValue)
                }
                value={
                  customerOptions.find(
                    (customer) =>
                      customer.name === InvoiceData1["Customer Name"]
                  ) || { id: "", name: "",customerNo:"" }
                }
                style={{ width: "309px" }}
              />
              <div style={{marginLeft:'30px'}}>
              <PheonixTextField
                id="outlined-error"
                type="text"
                placeholder="Phone Number"
                label="Phone Number"
                variant="outlined"
                size="small"
                name="Phone Number"
                value={InvoiceData1["Phone Number"]}
                onChange={(e) =>
                  handleInputChange(e.target.name, e.target.value)
                }
                error={!!fieldErrors?.["Phone Number"]}
                helperText={fieldErrors["Phone Number"]}
                style={{ width: "309px" }}
                readonly={true}
              />
              </div>
            </td>
            <td style={{ width: "25%", padding: "10px" }}>
              <PheonixTextField
                id="outlined-error"
                type="text"
                placeholder="Email"
                label="Email"
                variant="outlined"
                size="small"
                name="Email"
                value={InvoiceData1["Email"]}
                onChange={(e) =>
                  handleInputChange(e.target.name, e.target.value)
                }
                error={!!fieldErrors?.["Email"]}
                helperText={fieldErrors["Email"]}
                style={{ width: "309px" }}
                readonly={true}
              />
            </td>
            <td style={{ width: "25%" }}></td>
          </tr>
        </tbody>
      </table>
    </Box>
  );
};
export default InvoiceTable;
