import React, { useState, ChangeEvent, KeyboardEvent } from "react";
import PheonixSearchProps from "../interfaces/PheonixSearchProps";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const PheonixSearch: React.FC<PheonixSearchProps> = ({ onChange }) => {
  const [query, setquery] = useState("");

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setquery(event.target.value);
  };
  const handleSearchClick = () => {
    onChange(query);
  };
  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearchClick();
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <TextField
        variant="outlined"
        size="small"
        value={query}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder="Search"
        style={{
          marginTop: "10px",
          marginLeft: "10px",
          color: "#181D8C",
          border: "none",
          borderBottom: "2px solid #181D8C",
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleSearchClick}>
                <SearchIcon style={{ color: "#181D8C" }} />
              </IconButton>{" "}
            </InputAdornment>
          ),
          sx: {
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "& .MuiInputBase-input::placeholder": {
              color: "#181D8C",
            },
          },
        }}
      />
    </div>
  );
};

export default PheonixSearch;
