import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Typography,
  FormControl,
  InputLabel,
  Box,
  Button,
  TextField,
  IconButton,
} from "@mui/material";
import { TEXT_MESSAGES } from "../const";
import arrow from "../assets/arow.png";
import PheonixTextField from "../Components/PheonixTextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { GET_ALL_CUSTOMERS } from "../graphql/query";
import { ADD_INVOICE_MUTATION } from "../graphql/mutation";
import SnackBar from "../Components/PheonixSnackBar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import share from "../assets/share.png";
import dayjs from "dayjs";
import { api } from "../api";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import QuoteData from "../interfaces/PheonixQuotation";
import QuoteTable from "../Components/QuoteTable";
import { RowData } from "../interfaces/PheonixProductTableprops";

const initialQuoteData: QuoteData = {
  "Expiry Date": "",
  "Quotation Date": "",
  "Customer Notes": "",
  "Total Amount": "",
  "Tax Amount": "",
  "Discount Amount": "",
  Email: "",
  "Customer Id": "",
  "Quotation Notes": "",
  "Terms and Conditions": "",
  "Customer No": "",
};
interface CustomerOption {
  id: string;
  name?: string;
  email: string;
  number?: string;
  gst_no?: string;
  address?: string;
  customerNo: string;
  city?: string;
  state?: string;
  pincode?: string;
  country?: string;
}
interface ProductOrService {
  name: string | null;
  tax: string | null;
  amount: string | null;
  rate: string | null;
  quantity: string | null;
  productplan: string | null;
  billcycle: string | null;
  hsnCode: string | null;
}
const customer: CustomerOption = {
  id: "",
  name: "",
  email: "",
  number: "",
  gst_no: "",
  address: "",
  customerNo: "",
  city: "",
  state: "",
  pincode: "",
  country: "",
};
const NewQuotation: React.FC = () => {
  const [expiryDate, setExpiryDate] = useState<Date | null>(null);
  const [quotationDate, setQuotationDate] = useState<Date | null>(null);
  const [status, setStatus] = React.useState("");
  const [currency, setCurrency] = React.useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [convert, setconvert] = useState(false);
  const [succMessage, setSuccMessage] = useState("");
  const [quotesData, setQuotesData] = useState<QuoteData>(initialQuoteData);
  const [fieldErrors, setFieldErrors] = useState<QuoteData>(initialQuoteData);
  const [customerOptions, setCustomerOptions] = useState<CustomerOption[]>([
    customer,
  ]);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const editQuote = location.state?.editQuote || null;
  const view = location.state?.view;
  const [rows, setRows] = useState<RowData[]>([
    {
      "Product Details": "",
      Quantity: "",
      Rate: "",
      Tax: "",
      Amount: "",
      "Product Plan": "",
      "Billing Cycle": "",
      "HSN Number": "",
    },
  ]);
  type FieldErrors = {
    [index: string]: {
      [fieldName: string]: string;
    };
  };
  const [tableFieldErrors, setTableFieldErrors] = useState<FieldErrors>({});

  const [loggedInUser, setLoggedInUser] = useState<string>("");
  const [buttonDisable, setButtonDisable] = useState(true);
  const [rowDisable, setRowDisable] = useState(true);
  const [tableDisable, setTableDisable] = useState(false);
  const [confirmDisable, setConfirmDisable] = useState(false);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    const usertype = localStorage.getItem("usertype");
    if (usertype !== null) {
      setLoggedInUser(usertype);
    }
  }, []);

  const areAllFieldsHasValues = (data: QuoteData) => {
    return Object.values(data).every((value) => value !== "");
  };
  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        api.baseUrl,
        {
          query: GET_ALL_CUSTOMERS,
        },
        config
      );
      const customers = response.data.data.getAllUsers;
      const customersClient = customers.filter(
        (customer: any) => customer.user_type === "2"
      );
      if (customers) {
        const customerOptions = customersClient.map(
          (customer: {
            _id: string;
            customer_no: string;
            basic_info: {
              firstname: string;
              email: string;
              phone_number: string;
              shipping_address: {
                address_line1: string;
                city: string;
                state: string;
                pincode: string;
                country: string;
              };
            };
            billing_info: { GST_no: string };
          }) => ({
            id: customer._id,
            name: customer.basic_info.firstname,
            email: customer.basic_info.email,
            number: customer.basic_info.phone_number,
            gst_no: customer.billing_info.GST_no,
            address: customer.basic_info.shipping_address.address_line1,
            customerNo: customer.customer_no,
            city: customer.basic_info.shipping_address.city,
            state: customer.basic_info.shipping_address.state,
            pincode: customer.basic_info.shipping_address.pincode,
            country: customer.basic_info.shipping_address.country,
          })
        );
        setCustomerOptions(customerOptions);
      }
    } catch (error: any) {
      setError(error.message);
    }
  };

  const checkForErrors = (): boolean => {
    for (const rowIndex in tableFieldErrors) {
      for (const field in tableFieldErrors[rowIndex]) {
        if (tableFieldErrors[rowIndex][field]) {
          return true;
        }
      }
    }
    return false;
  };

  const areAllRowsComplete = (rows: RowData[]): boolean => {
    return rows.every((row) => {
      return Object.values(row).every((value) => value !== "");
    });
  };
  const handleBack = () => {
    navigate("/quotes");
  };
  useEffect(() => {
    const hasErrors = Object.values(fieldErrors).some((error) => !!error);
    setConfirmDisable(hasErrors);
  }, [fieldErrors]);

  useEffect(() => {
    const hasTableErrors = checkForErrors();
    setTableDisable(hasTableErrors);
  }, [tableFieldErrors]);

  useEffect(() => {
    const isButtonDisabled = areAllFieldsHasValues(quotesData);
    if (isButtonDisabled) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  }, [quotesData]);

  useEffect(() => {
    const isAllRows = areAllRowsComplete(rows);
    if (isAllRows) {
      setRowDisable(false);
    } else {
      setRowDisable(true);
    }
  }, [rows]);

  const handleClose = () => {
    setModalOpen(false);
  };

  const mapRowsToProductOrService = (rows: RowData[]): ProductOrService[] => {
    return rows.map((row) => ({
      name: row["Product Details"] || null,
      tax: row["Tax"] || null,
      amount: row["Amount"] || null,
      rate: row["Rate"] || null,
      quantity: row["Quantity"] || null,
      productplan: row["Product Plan"] || null,
      billcycle: row["Billing Cycle"] || null,
      hsnCode: row["HSN Number"] || null,
    }));
  };
  useEffect(() => {
    if (editQuote) {    
      setQuotesData({
        "Customer Id": editQuote.customerId,
        Email: editQuote.email,
        "Total Amount": editQuote.total,
        "Customer No": editQuote.customerNo,
        "Customer Notes": editQuote.customernotes,
        "Expiry Date": editQuote.duedate,
        "Quotation Date": editQuote.quotationdate,
        "Quotation Notes": editQuote.quotationnotes,
        "Terms and Conditions": editQuote.terms_conditions,
        "Tax Amount": editQuote.taxamount,
        "Discount Amount": editQuote.discount,
      });
      const customer = customerOptions.find(
        (customer) => customer.id === editQuote.customerId.toString()
      );
      if (customer) {
        setQuotesData((prevQuotesData) => ({
          ...prevQuotesData,
          "Customer ID": customer.id,
          "customer No": customer.customerNo
        }));
      }
      
      setRows(mapProductOrServiceToRows(editQuote.productOrService));
      setCurrency(editQuote?.currency || "");
    }
    setStatus(editQuote?.status || "");
  }, [editQuote]);

  const mapProductOrServiceToRows = (
    productOrService: ProductOrService[]
  ): RowData[] => {
    return productOrService.map((service) => ({
      "Product Details": service.name || "",
      Quantity: service.quantity || "",
      Rate: service.rate || "",
      Tax: service.tax || "",
      Amount: service.amount || "",
      "Product Plan": service.productplan || "",
      "Billing Cycle": service.billcycle || "",
      "HSN Number": service.hsnCode || "",
    }));
  };

  const customCloseIcon = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
      style={{ justifyContent: "end", width: "200px" }}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  const handleConvertToInvoice = async () => {
    const productOrService = mapRowsToProductOrService(rows);
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    const query = ADD_INVOICE_MUTATION;
    const variables = {
      user_id: userId,
      invoicedate: quotesData["Quotation Date"],
      duedate: quotesData["Expiry Date"],
      expiry_date: quotesData["Expiry Date"],
      productOrService: productOrService,
      price: quotesData["Total Amount"],
      paid_amount: "0",
      status: "Unpaid",
      customer_name: quotesData["Customer Name"],
      customer_id: quotesData["Customer Id"],
      email: quotesData["Email"],
      description: quotesData["Customer Notes"],
    };
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios.post(
        api.baseUrl,
        {
          query,
          variables,
        },
        config
      );
      const { addInvoice } = response.data.data;
      if (addInvoice && addInvoice.id) {
        setSuccMessage(TEXT_MESSAGES.INVOICE_SUCCESS);
        setconvert(true);
      } else {
        setSuccMessage(TEXT_MESSAGES.Invoice_FAILURE);
      }
      setModalOpen(true);
      setTimeout(() => {
        setModalOpen(false);
        navigate("/invoice");

      }, 3000);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSave = async () => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    const productOrService = mapRowsToProductOrService(rows);
    const query = `
    mutation AddQuotation($user_id: ID!
      $name: String
      $quotationdate: String!
      $productOrService: [ProductOrServiceInput!]!
      $customernotes: String
      $quotationnotes: String
      $currency: String
      $customerName: String
      $customerId:String!
      $customerNo: String
      $phoneNumber:String
      $gstNumber:String
      $email: String
      $address: String
      $duedate: String!
      $price: String!
      $discount: String
      $taxamount: String
      $terms_conditions: String
      $status: String! ){
      addQuotation(user_id: $user_id
        name: $name
        quotationdate: $quotationdate
        productOrService: $productOrService
        customernotes: $customernotes
        quotationnotes: $quotationnotes
        currency: $currency
        gstNumber:$gstNumber
        customerName: $customerName
        customerNo: $customerNo
        customerId: $customerId
        phoneNumber:$phoneNumber
        email:$email
        address:$address
        duedate: $duedate
        terms_conditions: $terms_conditions
        price: $price
        discount: $discount
        taxamount: $taxamount
        status: $status){
          id
          serial_no
          user_id
          name
          quotationid
          quotationdate
          productOrService {
        name
        tax
        amount
        rate
        quantity
      }
          duedate
          customernotes
          quotationnotes
          gstNumber
          currency
          customerName
          customerNo
          customerId
          phoneNumber
          email
          address
          terms_conditions
          price
          status
      }
    }
    `;

    const variables = {
      user_id: userId,
      name: quotesData["Customer Name"],
      quotationdate: quotesData["Quotation Date"],
      productOrService: productOrService,
      customernotes: quotesData["Customer Notes"],
      quotationnotes: quotesData["Quotation Notes"],
      customerName: quotesData["Customer Name"],
      customerId: quotesData["Customer Id"],
      customerNo:quotesData["Customer No"],
      email: quotesData["Email"] ? quotesData["Email"] : "",
      duedate: quotesData["Expiry Date"],
      price:
        typeof quotesData["Total Amount"] === "string"
          ? quotesData["Total Amount"]
          : String(quotesData["Total Amount"]),
      status: status,
      currency: currency,
      terms_conditions: quotesData["Terms and Conditions"],
      discount: quotesData["Discount Amount"],
      taxamount: quotesData["Tax Amount"],
    };
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        api.baseUrl,
        {
          query,
          variables,
        },
        config
      );
      const { addQuotation } = response.data.data;

      if (addQuotation && addQuotation.id) {
        setSuccMessage(TEXT_MESSAGES.QUOTATION_SUCCESS);
      } else {
        setSuccMessage(TEXT_MESSAGES.Quotation_FAILURE);
      }

      setModalOpen(true);
      setTimeout(() => {
        setModalOpen(false);
        navigate("/quotes");
      }, 3000);
    } catch (error) {
      console.error(error);
    }
  };
  const handleEditClick = () => {
    setEdit(true);
  };
  const handleEdit = async () => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    const productOrService = mapRowsToProductOrService(rows);
    const query = `
    mutation EditQuotation(
      $_id: ID!
      $user_id: ID!
      $name: String
      $quotationdate: String!
      $productOrService: [ProductOrServiceInput!]!
      $customernotes: String
      $quotationnotes: String
      $currency: String
      $customerName: String
      $customerId:String!
      $customerNo: String
      $phoneNumber:String
      $gstNumber:String
      $email: String!
      $address: String
      $duedate: String!
      $price: String!
      $discount: String
      $taxamount: String
      $terms_conditions: String
      $status: String! ){
        editQuotation(
        _id: $_id
        user_id: $user_id
        name: $name
        quotationdate: $quotationdate
        productOrService: $productOrService
        customernotes: $customernotes
        quotationnotes: $quotationnotes
        currency: $currency
        gstNumber:$gstNumber
        customerName: $customerName
        customerNo: $customerNo
        customerId: $customerId
        phoneNumber:$phoneNumber
        email:$email
        address:$address
        duedate: $duedate
        terms_conditions: $terms_conditions
        price: $price
        discount: $discount
        taxamount: $taxamount
        status: $status){
          id
          serial_no
          user_id
          name
          quotationid
          quotationdate
          productOrService {
        name
        tax
        amount
        rate
        quantity
      }
          duedate
          customernotes
          quotationnotes
          gstNumber
          currency
          customerName
          customerNo
          customerId
          phoneNumber
          email
          address
          terms_conditions
          price
          status
      }
    }
    `;

    const variables = {
      _id: editQuote.id,
      user_id: userId,
      name: quotesData["Customer Name"],
      quotationdate: quotesData["Quotation Date"],
      productOrService: productOrService,
      description: quotesData["Customer Notes"],
      quotationnotes: quotesData["Quotation Notes"],
      terms_conditions: quotesData["Terms and Conditions"],
      customerName: quotesData["Customer Name"],
      customerId: quotesData["Customer Id"],
      customerNo:quotesData["Customer No"],
      email: quotesData["Email"],
      duedate: quotesData["Expiry Date"],
      price:
        typeof quotesData["Total Amount"] === "string"
          ? quotesData["Total Amount"]
          : String(quotesData["Total Amount"]),
      status: status,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        api.baseUrl,
        {
          query,
          variables,
        },
        config
      );
      const { editQuotation } = response.data.data;

      if (editQuotation && editQuotation.id) {
        setSuccMessage(TEXT_MESSAGES.QUOTATION_UPDATE);
      } else {
        setSuccMessage(TEXT_MESSAGES.Quotation_UPDATE_FAILURE);
      }

      setModalOpen(true);
      setTimeout(() => {
        setModalOpen(false);
        navigate("/quotes");
      }, 3000);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancel = () => {
    setQuotesData({
      "Expiry Date": "",
      "Quotation Date": "",
      "Customer Notes": "",
      "Total Amount": "",
      "Tax Amount": "",
      "Discount Amount": "",
      "Customer Name": "",
      "Customer No": "",
      Email: "",
      "Quotation Notes": "",
      "Terms and Conditions": "",
    });
    navigate("/quotes");
  };

  return (
    <Box
      style={{
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "1%",
        }}
      >
        <Typography
          style={{
            fontSize: "24px",
            fontFamily: "Roboto",
            color: "#181D8C",
            fontWeight: 600,
            marginLeft: "1%",
            lineHeight: "29px",
          }}
        >
          {edit ? TEXT_MESSAGES.QUOTE_EDIT :
          editQuote
            ? TEXT_MESSAGES.QUOTE_VIEW 
            
            : TEXT_MESSAGES.CREATE_QUOTATION}
        </Typography>

        <div style={{ display: "flex", height: "35px" }}>
          {editQuote && (
            <Box
              sx={{
                backgroundColor: "#EAEBFF",
                marginRight: "3%",
                width: "338px",
                borderRadius: "8px",
                textAlign: "center",
                alignContent: "center",
              }}
            >
              {" "}
              <Typography
                style={{
                  fontSize: "18px",
                  fontFamily: "Roboto",
                  color: "#181D8C",
                  fontWeight: 600,
                  marginLeft: "2%",
                }}
              >
                {TEXT_MESSAGES.QUOTE_NUMBER}:{editQuote.quotationid}
              </Typography>
            </Box>
          )}
          <Button
            onClick={handleBack}
            sx={{
              color: "#181D8C",
              border: "1px solid",
              backgroundColor: "white",
              width: "208px",
              borderRadius: "5px",
              marginRight: "20px",
              textTransform: "none",
              fontSize: "14px",
              fontWeight: 500,
            }}
          >
            <img
              src={arrow}
              style={{
                marginRight: "10px",
                marginLeft: 0,
                height: "16px",
                width: "18px",
              }}
            />
            {TEXT_MESSAGES.BACK_QUOTE}
          </Button>
        </div>
      </Box>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "15px",
          border: "0.5px solid #B7BAFF",
          padding: "5px",
          width: "97%",
          marginLeft: "1%",
          borderRadius: "4px",
          height: "62vh",
          overflowY: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            margin: "5px",
            padding: "5px",
            marginTop: "0px",
            marginLeft: "-5px",
            marginRight: "0%",
            width: "95%",
          }}
        >
          {editQuote?.status === "Accepted" ? (
            <Box
              sx={{
                display: "flex",
                alignContent: "center",
                justifyContent: "flex-end",
                flexDirection: "row",
                marginTop: "1%",
              }}
            >
             {!convert && <Button
                onClick={handleConvertToInvoice}
                sx={{
                  color: "white",
                  border: "1px solid",
                  backgroundColor: "#181D8C",
                  height: "35px",
                  borderRadius: "5px",

                  textTransform: "none",
                  "&:hover": { backgroundColor: "#181D8C" },
                  "&:active": { backgroundColor: "#181D8C" },
                }}
              >
                {TEXT_MESSAGES.CONVERT_TXT}
              </Button>}
              <Button
                sx={{
                  color: "white",
                  border: "1px solid",
                  backgroundColor: "#181D8C",
                  height: "35px",
                  borderRadius: "5px",
                  textTransform: "none",
                  "&:hover": { backgroundColor: "#181D8C" },
                  "&:active": { backgroundColor: "#181D8C" },
                }}
              >
                PDF/Print
              </Button>
              <Button
                onClick={handleEditClick}
                disabled={edit}
                sx={{
                  color: "white",
                  border: "1px solid",
                  backgroundColor: "#181D8C",
                  height: "35px",
                  borderRadius: "5px",
                  textTransform: "none",
                  "&:hover": { backgroundColor: "#181D8C" },
                  "&:active": { backgroundColor: "#181D8C" },
                }}
              >
                Edit
              </Button>
            </Box>
          ) : editQuote ? (
            <Box
              sx={{
                display: "flex",
                alignContent: "center",
                justifyContent: "flex-end",
                flexDirection: "row",
                marginTop: "1%",
              }}
            >
              <Button
                sx={{
                  color: "white",
                  border: "1px solid",
                  backgroundColor: "#181D8C",
                  height: "35px",
                  borderRadius: "5px",
                  textTransform: "none",
                  "&:hover": { backgroundColor: "#181D8C" },
                  "&:active": { backgroundColor: "#181D8C" },
                }}
              >
                PDF/Print
              </Button>
              <Button
                onClick={handleEditClick}
                disabled={edit}
                sx={{
                  color: "white",
                  border: "1px solid",
                  backgroundColor: edit ? "lightgrey" : "#181D8C",
                  height: "35px",
                  borderRadius: "5px",
                  textTransform: "none",
                  "&:hover": { backgroundColor: "#181D8C" },
                  "&:active": { backgroundColor: "#181D8C" },
                }}
              >
                Edit
              </Button>
            </Box>
          ) : (
            ""
          )}
          <QuoteTable
            QuotesData={quotesData}
            setQuotesData={setQuotesData}
            status={status}
            setStatus={setStatus}
            currency={currency}
            setCurrency={setCurrency}
            QuotationDate={quotationDate}
            setQuotationDate={setQuotationDate}
            ExpiryDate={expiryDate}
            setExpiryDate={setExpiryDate}
            fieldErrors={fieldErrors}
            setFieldErrors={setFieldErrors}
            customerOptions={customerOptions}
            setCustomerOptions={setCustomerOptions}
            rows={rows}
            setRows={setRows}
            tableFieldErrors={tableFieldErrors}
            setTableFieldErrors={setTableFieldErrors}
            view={view}
            edit={edit}
          />
        </Box>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          alignSelf: "flex-end",
          marginTop: "10px",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            height: "0.5px",
            width: "100%",
            backgroundColor: "#CBC2E9",
          }}
        ></div>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <Button
            onClick={handleCancel}
            style={{
              color: "#181d8C",
              backgroundColor: "white",
              border: "1px solid #181D8C",
              width: "125px",
              height: "36px",
              borderRadius: "5px",
              marginLeft: "2%",
            }}
          >
            {TEXT_MESSAGES.CANCEL}
          </Button>
          {edit ? (
            <Button
              onClick={handleEdit}
              disabled={buttonDisable || confirmDisable}
              sx={{
                color: "white",
                border: "1px solid",
                backgroundColor:
                  buttonDisable || confirmDisable || rowDisable || tableDisable
                    ? "lightgray"
                    : "#181D8C",
                width: "125px",
                height: "36px",
                borderRadius: "5px",
                marginRight: "2%",
                "&:hover": { backgroundColor: "#181D8C" },
                "&:active": { backgroundColor: "#181D8C" },
              }}
            >
              {TEXT_MESSAGES.UPDATE_TXT}
            </Button>
          ) : (
            view === undefined && (
              <Button
                onClick={handleSave}
                disabled={buttonDisable || confirmDisable}
                sx={{
                  color: "white",
                  border: "1px solid",
                  backgroundColor:
                    buttonDisable ||
                    confirmDisable ||
                    rowDisable ||
                    tableDisable
                      ? "lightgray"
                      : "#181D8C",
                  width: "125px",
                  height: "36px",
                  borderRadius: "5px",
                  marginRight: "2%",
                  "&:hover": { backgroundColor: "#181D8C" },
                  "&:active": { backgroundColor: "#181D8C" },
                }}
              >
                {TEXT_MESSAGES.SAVE}
              </Button>
            )
          )}
        </Box>
      </div>
      <SnackBar
        open={modalOpen}
        onClose={handleClose}
        message={succMessage}
        vertical="top"
        horizontal="center"
        customAction={customCloseIcon}
      />
    </Box>
  );
};
export default NewQuotation;
