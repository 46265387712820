import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import CustomToolBar from "./CustomToolBar";
import PheonixDataGridProps from "../interfaces/PheonixGridProps";

const useStyles = makeStyles({
  virtualScrollerContent: {
    paddingTop: "20px",
  },
});

const PheonixDataGrid: React.FC<PheonixDataGridProps> = ({
  rows,
  columns,
  components,
  style,
  pageSizeOptions,
  paginationModel,
  rowCount,
  onPaginationModelChange,
  onSearchQueryChange,
  searchQuery,
  paginationMode,
  ...props
}) => {
  const classes = useStyles();

  const getRowClassName = (params: any) => {
    return params.index % 2 === 0 ? "even-row" : "odd-row";
  };

  return (
    <div style={{ width: "100%", overflow: 'auto' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        components={{
          Toolbar: (props) => <CustomToolBar {...props} onSearchQueryChange={onSearchQueryChange} />, 
          ...components,
        }}
        paginationModel={paginationModel}
        rowCount={rowCount}
        paginationMode={paginationMode || "server"}
        onPaginationModelChange={onPaginationModelChange}
        pageSizeOptions={pageSizeOptions || [5, 10, 20, 30]}
        style={style}
        getRowClassName={getRowClassName}
        classes={{ virtualScrollerContent: classes.virtualScrollerContent }}
        disableRowSelectionOnClick
        {...props}
      />
    </div>
  );
};

export default PheonixDataGrid;
