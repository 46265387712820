import React, { useState, useEffect } from "react";
import axios from "axios";
import PheonixCard from "../Components/PheonixCard";
import { ERROR_MESSAGES, TEXT_MESSAGES } from "../const";
import { api } from "../api";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Typography,
  fabClasses,
} from "@mui/material";
import PheonixTextField from "../Components/PheonixTextField";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Switch from "@mui/material/Switch";
import { makeStyles } from '@mui/styles';
import { config } from "dotenv";
const useStyles = makeStyles({
  switchBase: {
    '&.Mui-checked': { 
      color: 'rgb(24, 29, 140)',
    },
    '&.Mui-checked + .MuiSwitch-track': { 
      backgroundColor: 'rgb(24, 29, 140)', 
    },
  },
});
const label = { inputProps: { 'aria-label': 'Color switch demo' } };

interface FieldErrors {
  [key: string]: string;
}

const Profilepage: React.FC = () => {
  const [userData, setUserData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const userId = localStorage.getItem("userId");
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedData, setEditedData] = useState<any>({});
  const [isVisible, setIsVisible] = useState(true);
  const [isVisible1, setIsVisible1] = useState(true);
  const [isVisible2, setIsVisible2] = useState(true);
  const [updateeditedData, setUpdateEditedData] = useState<any>({});
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({});
  const [confirmDisable, setConfirmDisable] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const toggleVisibility1 = () => {
    setIsVisible1(!isVisible1);
  };

  const toggleVisibility2 = () => {
    setIsVisible2(!isVisible2);
  };
  const [contactRows, setContactRows] = useState([{ id: 1 }]);
  const handleAddRow = () => {
    const newRowId = contactRows.length + 1;
    setContactRows([...contactRows, { id: newRowId }]);
  };

  const handleRemoveRow = (idToRemove: number) => {
    const updatedRows = contactRows.filter((row) => row.id !== idToRemove);
    setContactRows(updatedRows);
  }; 
  useEffect(() => {
    const token = localStorage.getItem("token"); 
    const fetchUserData = async () => {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const response = await axios.post(api.baseUrl, {
          query: `
                        query GetUserById($userId: ID!) {
                            getUserById(id: $userId) {
                                basic_info {
                                    company_name
                                    website
                                    email
                                    firstname
                                    customer_type
                                  
                                      shipping_address{
             
                                        address_line1
                                        city
                                        state
                                        zipcode
                                        country
                                    }
                                    lastname
                                    phone_number
                                }
                                contact_info {
                                    contact_name
                                    contact_no
                                    contact_email
                                }
                                billing_info {
                                    GST_type
                                    payment_terms
                                    billing_address {
                                        address_line1
                                        city
                                        state
                                        zipcode
                                        country
                                    }
                                    GST_no
                                    currency
                                    business_name
                                }
                                portal_info {
                                    email
                                    password
                                }
                            }
                        }
                    `,
          variables: {
            userId: userId,
          },
        }, config);
        setUserData(response.data.data.getUserById);
        setLoading(false);
      } catch (error: any) {
        setError(error.message);
        setLoading(false);
      }
    };
    if (userId) {
      fetchUserData();
    }
  }, []);

  const editUser = async (
    id: string,
    basic_info: {
      firstname: string;
      lastname: string;
      email: string;
      phone_number: string;
      customer_type: string;
      company_name: string;
      website: string;
      shipping_address: {
        address_line1: string;
        city: string;
        state: string;
        zipcode: string;
        country: string;
      };
    },
    contact_info: {
      contact_name: string;
      contact_no: string;
      contact_email: string;
    },
    billing_info: {
      GST_type: string;
      GST_no: string;
      payment_terms: string;
      currency: string;
      business_name: string;
      billing_address: {
        address_line1: string;
        city: string;
        state: string;
        zipcode: string;
        country: string;
      };
    },
    portal_info: {
      email: string;
      password: string;
    }
  ) => {
    const query = `
      mutation UpdateUser(
        $id: ID!,
        $basic_info: BasicInfoInput,
        $contact_info: ContactInfoInput,
        $billing_info: BillingInfoInput,
        $portal_info: PortalInfoInput
      ) {
        updateUser(
          id: $id,
          basic_info: $basic_info,
          contact_info: $contact_info,
          billing_info: $billing_info,
          portal_info: $portal_info
        ) {
          id
          basic_info {
            firstname
            lastname
            email
            phone_number
            shipping_address {
              address_line1
              city
              state
              zipcode
              country
            }
            customer_type
            company_name
            website
          }
          contact_info {
            contact_name
            contact_no
            contact_email
          }
          billing_info {
            GST_type
            GST_no
            payment_terms
            currency
            business_name
            billing_address {
              address_line1
              city
              state
              zipcode
              country
            }
          }
          portal_info {
            email
            password
          }
        }
      }
    `;

    const variables = {
      id,
      basic_info,
      contact_info,
      billing_info,
      portal_info,
    };
    const token = localStorage.getItem("token"); 
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(api.baseUrl, {
        query,
        variables,
      }, config
    );

      const userData = response.data.data.updateUser;
      setUserData(userData);
      setUpdateEditedData(userData);
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const isMobileView = window.innerWidth <= 768;
  const customLabels: Record<string, string> = {
    customer_type: "Customer Type",
    firstname: "First Name",
    email: "Email",
    shipping_address: "Shipping Address",
    company_name: "Company Name",
    lastname: "Last Name",
    phone_number: "Phone Number",
    website: "Website URL",
    contact_name: "Contact Name",
    contact_no: "Phone Number",
    contact_email: "Email",
    GST_type: "GST Type",
    payment_terms: "Payment Terms",
    billing_address: "Billing Address",
    GST_no: "GSTIN",
    currency: "Currency",
    business_name: "Legal Business Name",
    password: "Password",
  };

  const basicInfoLabels: Record<string, string> = {
    customer_type: "Customer Type",
    firstname: "First Name",
    email: "Email",
    shipping_address: "Shipping Address",
    company_name: "Company Name",
    lastname: "Last Name",
    phone_number: "Phone Number",
    website: "Website URL",
  };

  const handleEdit = () => {
    setIsEditMode(true);
    setUpdateEditedData(userData);
    setEditedData(userData);
  };

  const handleChange1 = (section: string, key: string, value: string) => {
    setUpdateEditedData((prevData: any) => {
      const updatedData = { ...prevData };
      const sections = section.split(".");
      let target = updatedData;

      for (let i = 0; i < sections.length - 1; i++) {
        target = target[sections[i]];
      }

      target[sections[sections.length - 1]][key] = value;

      return { ...updatedData };
    });
  };

  useEffect(() => {
    const hasErrors = Object.values(fieldErrors).some((error) => !!error);
    setConfirmDisable(hasErrors);
  }, [fieldErrors]);

  const handleChange = (key: any, value: string, rowID?:number) => {
    const updatedData = {
      ...updateeditedData,
      basic_info: {
        ...updateeditedData.basic_info,
        [key]: value,
      },
      billing_info: {
        ...updateeditedData.billing_info,
        [key]: value,
      },
      contact_info: {
        ...updateeditedData.contact_info,
        [`${key}${rowID !== undefined ? rowID : ""}`]: value,
      },
    };
    setUpdateEditedData(updatedData);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      [`${key}${rowID !== undefined ? rowID : ""}`]: "",
    }));
  
    if (value === "") {
      const { [key]: omit, ...rest } = updatedData.basic_info;
      setUpdateEditedData({ ...updatedData, basic_info: rest });
    }
    if (key === "phone_number" || key === "contact_no" ) {
      if (!/^[0-9]*$/.test(value)) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          [key]: ERROR_MESSAGES.NUMBER_ONLY
        }));
      } else if (value.length !== 10) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          [key]: ERROR_MESSAGES.NUMBER_10_LENGTH
        }));
      } else {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          [key]: ""
        }));
      }
    } else {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [key]: ""
      }));
    }
    if (key === "contact_email") {
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          [key]: ERROR_MESSAGES.INVALID_EMAIL_FORMAT
        }));
      } else {
        setFieldErrors((prevErrors) => ({ ...prevErrors, [key]: "" }));
      }
    }
    if (key === "GST_no") {
      if (!value) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          [key]: ERROR_MESSAGES.GST_NO_IS_REQUIRED
        }));
      } else if (!/^[a-zA-Z0-9]+$/.test(value)) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          [key]: ERROR_MESSAGES.GST_NO_SHOULD
        }));
      } else if (value.length !== 15) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          [key]: ERROR_MESSAGES.GST_NO_SHOULD_1
        }));
      } else {
        setFieldErrors((prevErrors) => ({ ...prevErrors, [key]: "" }));
      }
    }
    
    
  };

  const handleCancel = () => {
    setIsEditMode(false);
  };

  const handleSave = async () => {
    const id = userData?.id || userId;

    if (!id) {
      console.error("User ID is missing");
      return;
    }

    await editUser(
      id,
      {
        firstname: updateeditedData.basic_info.firstname || "",
        lastname: updateeditedData.basic_info.lastname || "",
        email: updateeditedData.basic_info.email || "",
        phone_number: updateeditedData.basic_info.phone_number || "",
        customer_type: updateeditedData.basic_info.customer_type || "",
        company_name: updateeditedData.basic_info.company_name || "",
        website: updateeditedData.basic_info.website || "",
        shipping_address: {
          address_line1:
            updateeditedData.basic_info.shipping_address?.address_line1 || "",
          city: updateeditedData.basic_info.shipping_address?.city || "",
          state: updateeditedData.basic_info.shipping_address?.state || "",
          zipcode: updateeditedData.basic_info.shipping_address?.zipcode || "",
          country: updateeditedData.basic_info.shipping_address?.country || "",
        },
      },
      {
        contact_name: updateeditedData.contact_info?.contact_name || "",
        contact_no: updateeditedData.contact_info?.contact_no || "",
        contact_email: updateeditedData.contact_info?.contact_email || "",
      },
      {
        GST_type: updateeditedData.billing_info?.GST_type || "",
        GST_no: updateeditedData.billing_info?.GST_no || "",
        payment_terms: updateeditedData.billing_info?.payment_terms || "",
        currency: updateeditedData.billing_info?.currency || "",
        business_name: updateeditedData.billing_info?.business_name || "",
        billing_address: {
          address_line1:
            updateeditedData.billing_info.billing_address?.address_line1 || "",
          city: updateeditedData.billing_info.billing_address?.city || "",
          state: updateeditedData.billing_info.billing_address?.state || "",
          zipcode: updateeditedData.billing_info.billing_address?.zipcode || "",
          country: updateeditedData.billing_info.billing_address?.country || "",
        },
      },
      {
        email: updateeditedData.portal_info?.email || "",
        password: updateeditedData.portal_info?.password || "",
      }
    );
    setIsEditMode(false);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: isMobileView ? "0" : "40px",
      }}
    >
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {userData && (
        <>
          <Paper>
            <Box
              sx={{
                boxShadow: "0px 4px 2px -2px rgba(0,0,0,0.2)",
                padding: 1.5,
                borderRadius: "7.5px 0px 0px 0px",
                height: "50px",
              }}
            >
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  color: "#032C43",
                  fontWeight: "bold",
                  marginLeft: "1.2%",
                  marginTop: "0.7%",
                }}
              >
                {TEXT_MESSAGES.PROFILE}
              </Typography>
            </Box>
            <Box sx={{ marginLeft: "2%", marginTop: "3%" }}>
              <PheonixCard
                isMobileView={isMobileView}
                title={TEXT_MESSAGES.BASIC_INFO}
                buttonDisabled={false}
                onClickButton={isEditMode ? handleSave : handleEdit}
              >
                <IconButton
                  onClick={toggleVisibility}
                  sx={{ marginLeft: "95%", marginTop: "-2%" }}
                >
                  {isVisible ? (
                    <RemoveIcon sx={{ color: "#69696E" }} />
                  ) : (
                    <AddIcon sx={{ color: "#69696E" }} />
                  )}
                </IconButton>
                {isVisible && (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {["company_name", "website"].map((key) => (
                        <div
                          key={key}
                          style={{
                            marginLeft: isMobileView ? "0px" : "3px",
                            marginBottom: "1rem",
                            marginRight: "2.5rem",
                            minWidth: "200px",
                          }}
                        >
                          {isEditMode ? (
                            <PheonixTextField
                              id={key}
                              type="text"
                              value={updateeditedData.basic_info[key] || ""}
                              label={customLabels[key]}
                              onChange={(e) =>
                                handleChange(key, e.target.value)
                              }
                              disabled={!isEditMode}
                              placeholder={`Enter ${basicInfoLabels[key]}`}
                            />
                          ) : (
                            <>
                              <p
                                style={{
                                  color: "rgba(0, 0, 0, 0.6)",
                                  fontSize: "14px",
                                  fontWeight: 400,
                                }}
                              >
                                {basicInfoLabels[key]}
                              </p>
                              <div
                                style={{
                                  fontWeight: 400,
                                  color: "#000000",
                                  fontSize: "14px",
                                }}
                              >
                                {(userData.basic_info[key] as string) || "-"}
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {["firstname", "lastname", "email", "phone_number"].map(
                        (key) => (
                          <div
                            key={key}
                            style={{
                              marginLeft: isMobileView ? "0px" : "3px",
                              marginBottom: "1rem",
                              marginRight: "2.5rem",
                              minWidth: "200px",
                            }}
                          >
                            {isEditMode ? (
                              <PheonixTextField
                                id={key}
                                type="text"
                                value={updateeditedData.basic_info[key] || ""}
                                label={customLabels[key]}
                                onChange={(e) =>
                                  handleChange(key, e.target.value)
                                }
                                disabled={key === "email" || !isEditMode}
                                placeholder={`Enter ${basicInfoLabels[key]}`}
                                error={!!fieldErrors[key]}
                                helperText={fieldErrors[key]}
                              />
                            ) : (
                              <>
                                <p
                                  style={{
                                    color: "rgba(0, 0, 0, 0.6)",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                  }}
                                >
                                  {basicInfoLabels[key]}
                                </p>
                                <div
                                  style={{
                                    fontWeight: 400,
                                    color: "#000000",
                                    fontSize: "14px",
                                  }}
                                >
                                  {(userData.basic_info[key] as string) || "-"}
                                </div>
                              </>
                            )}
                          </div>
                        )
                      )}
                    </div>
                    <div>
                      <div>
                        {!isEditMode && (
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div
                              key="shipping_address"
                              style={{
                                marginLeft: isMobileView ? "0px" : "3px",
                                marginBottom: "1rem",
                                minWidth: "200px",
                              }}
                            >
                              <p
                                style={{
                                  color: "rgba(0, 0, 0, 0.6)",
                                  fontSize: "14px",
                                  fontWeight: 400,
                                }}
                              >
                                {basicInfoLabels.shipping_address}
                              </p>
                              <div
                                style={{
                                  fontWeight: 400,
                                  color: "#000000",
                                  fontSize: "14px",
                                }}
                              >
                                {Object.entries(
                                  userData.basic_info.shipping_address
                                ).map(([key, value]) => (
                                  <p key={key}>
                                    <span style={{ fontWeight: 600 }}>
                                      {customLabels[key]}
                                    </span>{" "}
                                    {(value as string) || "-"}
                                  </p>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                        {isEditMode && (
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div
                              key="shipping_address"
                              style={{
                                marginLeft: isMobileView ? "0px" : "3px",
                                marginBottom: "1rem",
                                minWidth: "200px",
                              }}
                            >
                              <p
                                style={{
                                  color: "rgba(0, 0, 0, 0.6)",
                                  fontSize: "14px",
                                  fontWeight: 400,
                                }}
                              >
                                {basicInfoLabels.shipping_address}
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  flexWrap: "wrap",
                                }}
                              >
                                {["address_line1", "city", "state"].map(
                                  (key) => (
                                    <div
                                      key={key}
                                      style={{
                                        marginRight: "2.5rem",
                                        marginBottom: "1.5rem",
                                        minWidth: "200px",
                                      }}
                                    >
                                      <PheonixTextField
                                        id={key}
                                        type="text"
                                        value={
                                          updateeditedData.basic_info
                                            .shipping_address[key] || ""
                                        }
                                        label={key
                                          .split("_")
                                          .join(" ")
                                          .toUpperCase()}
                                        onChange={(e) =>
                                          handleChange("shipping_address", {
                                            ...updateeditedData.basic_info
                                              .shipping_address,
                                            [key]: e.target.value,
                                          })
                                        }
                                        disabled={!isEditMode}
                                        placeholder={`Enter ${key
                                          .split("_")
                                          .join(" ")}`}
                                      />
                                    </div>
                                  )
                                )}
                                {["country", "zipcode"].map((key) => (
                                  <div
                                    key={key}
                                    style={{
                                      marginRight: "3rem",
                                      marginBottom: "1rem",
                                      minWidth: "200px",
                                    }}
                                  >
                                    <PheonixTextField
                                      id={key}
                                      type="text"
                                      value={
                                        updateeditedData.basic_info
                                          .shipping_address[key] || ""
                                      }
                                      label={key
                                        .split("_")
                                        .join(" ")
                                        .toUpperCase()}
                                      onChange={(e) =>
                                        handleChange("shipping_address", {
                                          ...updateeditedData.basic_info
                                            .shipping_address,
                                          [key]: e.target.value,
                                        })
                                      }
                                      disabled={!isEditMode}
                                      placeholder={`Enter ${key
                                        .split("_")
                                        .join(" ")}`}
                                    />
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </PheonixCard>
            </Box>


            <Box sx={{ marginLeft: "2%", marginTop: "3%" }}>
            <PheonixCard
                isMobileView={isMobileView}
                title={TEXT_MESSAGES.CONTACT_INFO}
                buttonDisabled={false}
                onClickButton={isEditMode ? handleSave : handleEdit}
              >
                <IconButton onClick={toggleVisibility1} sx={{ marginLeft: '95%', marginTop: '-2%' }}>
                  {isVisible1 ? <RemoveIcon sx={{ color: '#69696E' }} /> : <AddIcon sx={{ color: '#69696E' }} />}
                </IconButton>
                {isVisible1 && (
                  <div>
                    {userData.contact_info.map((contact: any, index: number) => (
                      <div key={index} style={{ marginBottom: '1rem' }}>
                        <Typography variant="h6">{`Contact ${index + 1}`}</Typography>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                          {['contact_name', 'contact_no', 'contact_email'].map((key) => (
                            <div
                              key={key}
                              style={{
                                marginLeft: isMobileView ? '0px' : '3px',
                                marginBottom: '1rem',
                                marginRight: '2.5rem',
                                minWidth: '200px',
                              }}
                            >
                              {isEditMode ? (
                                <PheonixTextField
                                  id={`${key}${index}`}
                                  type="text"
                                  value={contact[key] || ''}
                                  label={customLabels[key]}
                                  onChange={(e) => handleChange(key, e.target.value, index)}
                                  disabled={!isEditMode}
                                  placeholder={`Enter ${customLabels[key]}`}
                                />
                              ) : (
                                <>
                                  <p
                                    style={{
                                      color: 'rgba(0, 0, 0, 0.6)',
                                      fontSize: '14px',
                                      fontWeight: 600,
                                      fontFamily: 'Arial',
                                      lineHeight: '20px',
                                    }}
                                  >
                                    {customLabels[key]}
                                  </p>
                                  <p
                                    style={{
                                      fontSize: '16px',
                                      fontWeight: 400,
                                      fontFamily: 'Arial',
                                      lineHeight: '24px',
                                      color: 'rgba(0, 0, 0, 0.87)',
                                    }}
                                  >
                                    {contact[key] || 'N/A'}
                                  </p>
                                </>
                              )}
                            </div>
                          ))}
                          {isEditMode && (
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => handleRemoveRow(index)}
                              sx={{ height: '40px', alignSelf: 'center' }}
                            >
                              Remove
                            </Button>
                          )}
                        </div>
                      </div>
                    ))}
                    {isEditMode && (
                      <Button variant="contained" color="primary" onClick={handleAddRow}>
                        Add Contact
                      </Button>
                    )}
                  </div>
                )}
              </PheonixCard>

    </Box>
            <Box sx={{ marginLeft: "2%", marginTop: "3%" }}>
              <PheonixCard
                isMobileView={isMobileView}
                title={TEXT_MESSAGES.BILLING_INFO}
                buttonDisabled={false}
                onClickButton={() => {}}
              >
                <IconButton
                  onClick={toggleVisibility2}
                  sx={{ marginLeft: "95%", marginTop: "-2%" }}
                >
                  {isVisible2 ? (
                    <RemoveIcon sx={{ color: "#69696E" }} />
                  ) : (
                    <AddIcon sx={{ color: "#69696E" }} />
                  )}
                </IconButton>
                {isVisible2 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: isMobileView ? "column" : "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {["GST_type", "GST_no", "business_name"].map((key) => (
                        <div
                          key={key}
                          style={{
                            marginLeft: isMobileView ? "0px" : "3px",
                            marginBottom: "1rem",
                            marginRight: "9rem",
                            minWidth: "200px",
                          }}
                        >
                          {isEditMode ? (
                            <PheonixTextField
                              id={key}
                              type="text"
                              value={updateeditedData.billing_info[key] || ""}
                              label={customLabels[key]}
                              onChange={(e) =>
                                handleChange(key, e.target.value)
                              }
                              placeholder={`Enter ${customLabels[key]}`}
                              disabled={!isEditMode}
                              error={!!fieldErrors[key]}
                              helperText={fieldErrors[key]}
                            />
                          ) : (
                            <>
                              <p
                                style={{
                                  color: "rgba(0, 0, 0, 0.6)",
                                  fontSize: "14px",
                                  fontWeight: 400,
                                }}
                              >
                                {customLabels[key]}
                              </p>
                              <div
                                style={{
                                  fontWeight: 400,
                                  color: "#000000",
                                  fontSize: "14px",
                                }}
                              >
                                {userData.billing_info[key] || "-"}
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                    
                    <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: isMobileView ? "column" : "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {["payment_terms", "currency"].map((key) => (
                        <div
                          key={key}
                          style={{
                            marginLeft: isMobileView ? "0px" : "3px",
                            marginBottom: "1rem",
                            marginRight: "9rem",
                            minWidth: "200px",
                            }}
                        >
                          {isEditMode ? (
                            <PheonixTextField
                              id={key}
                              type="text"
                              value={updateeditedData.billing_info[key] || ""}
                              label={customLabels[key]}
                              onChange={(e) =>
                                handleChange(key, e.target.value)
                              }
                              placeholder={`Enter ${customLabels[key]}`}
                              disabled={!isEditMode}
                            />
                          ) : (
                            <>
                              <p
                                style={{
                                  color: "rgba(0, 0, 0, 0.6)",
                                  fontSize: "14px",
                                  fontWeight: 400,
                                }}
                              >
                                {customLabels[key]}
                              </p>
                              <div
                                style={{
                                  fontWeight: 400,
                                  color: "#000000",
                                  fontSize: "14px",
                                }}
                              >
                                {userData.billing_info[key] || "-"}
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                    </div>

                    <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: isMobileView ? "column" : "row",
                        flexWrap: "wrap",
                      }}
                    >
                      <div
                        key="billing_address"
                        style={{
                          marginLeft: isMobileView ? "0px" : "3px",
                          marginBottom: "1rem",
                          minWidth: "200px",
                        }}
                      >
                        <p
                          style={{
                            color: "rgba(0, 0, 0, 0.6)",
                            fontSize: "14px",
                            fontWeight: 400,
                          }}
                        >
                          {customLabels.billing_address}
                        </p>
                        <div
                          style={{
                            fontWeight: 400,
                            color: "#000000",
                            fontSize: "14px",
                          }}
                        >
                          {isEditMode ? (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  flexWrap: "wrap",
                                }}
                              >
                                {["address_line1", "city", "state"].map(
                                  (key) => (
                                    <div
                                      key={key}
                                      style={{
                                        marginLeft: isMobileView ? "0px" : "3px",
                                        marginBottom: "1rem",
                                        marginRight: "9rem",
                                        minWidth: "200px",
                                      }}
                                    >
                                      <PheonixTextField
                                        id={key}
                                        type="text"
                                        value={
                                          updateeditedData.billing_info
                                            .billing_address[key] || ""
                                        }
                                        label={key
                                          .split("_")
                                          .join(" ")
                                          .toUpperCase()}
                                        onChange={(e) =>
                                          handleChange1(
                                            "billing_info.billing_address",
                                            key,
                                            e.target.value
                                          )
                                        }
                                        placeholder={`Enter ${key
                                          .split("_")
                                          .join(" ")}`}
                                      />
                                    </div>
                                  )
                                )}
                                </div>
                              
                              
                              <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                                {["zipcode", "country"].map((key) => (
                                  <div
                                    key={key}
                                    style={{ 
                                      marginLeft: isMobileView ? "0px" : "3px",
                                      marginBottom: "1rem",
                                      marginRight: "9rem",
                                      minWidth: "200px",
                                    }}
                                  >
                                    <PheonixTextField
                                      id={key}
                                      type="text"
                                      value={
                                        updateeditedData.billing_info
                                          .billing_address[key] || ""
                                      }
                                      label={key
                                        .split("_")
                                        .join(" ")
                                        .toUpperCase()}
                                      onChange={(e) =>
                                        handleChange1(
                                          "billing_info.billing_address",
                                          key,
                                          e.target.value
                                        )
                                      }
                                      placeholder={`Enter ${key
                                        .split("_")
                                        .join(" ")}`}
                                    />
                                  </div>
                                ))}
                              </div>
                            </>
                          ) : (
                            Object.entries(
                              userData.billing_info.billing_address
                            ).map(([addressKey, addressValue]) => (
                              <p key={addressKey}>{addressValue as string}</p>
                            ))
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                )}
              </PheonixCard>
            </Box>

            <Divider
              style={{
                marginBottom: "1.5rem",
                marginTop: "0.5rem",
                marginRight: "20px",
              }}
            />

            <Button
              variant="contained"
              size="large"
              onClick={isEditMode ? handleSave : handleEdit}
              disabled={confirmDisable}
              sx={{
                fontWeight: "bold",
                height: "36px",
                right: "-85%",
                width: "125px",
                color: "#FFFFFF",
                marginBottom: "3%",
                backgroundColor:  (confirmDisable) ? "grey" : "#181D8C",
                "&:hover": {
                  backgroundColor: "#181D8C",
                },
              }}
            >
              {isEditMode ? "Save" : "Edit"}
            </Button>
            {isEditMode && (
              <Button
                variant="contained"
                size="large"
                onClick={handleCancel}
                sx={{
                  fontWeight: "bold",
                  height: "36px",
                  left: "-7%",
                  width: "125px",
                  marginTop: "-3%",
                  color: "#181D8C",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #181D8C",
                  "&:hover": {
                    backgroundColor: "#FFFFFF",
                  },
                }}
              >
                Cancel
              </Button>
            )}
          </Paper>
        </>
      )}
    </div>
  );
};

export default Profilepage;
