import React, { useState, useEffect } from "react";
import PheonixHomeCard from "../Components/PheonixHomeCard";
import {
  Grid,
  useMediaQuery,
  useTheme,
  styled,
  Card,
  Button,
} from "@mui/material";
import Orders from "@mui/icons-material/ListAltOutlined";
import Products from "@mui/icons-material/Category";
import Quotation from "@mui/icons-material/RequestQuoteOutlined";
import Invoice1 from "@mui/icons-material/ReceiptOutlined";
import Invoice from "@mui/icons-material/ReceiptLongOutlined";
import { CardContent, CardMedia, Typography, Box } from "@mui/material";
import { api } from "../api";
import { GET_CLIENT_DASHBOARD } from "../graphql/query";
import axios from "axios";
import { productsdata, TEXT_MESSAGES } from "../const";
import productvector from "../assets/productvector.png";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { productsData, productsFullData, items } from "./productsmockdata";
import ServicesCard from "../Components/ServicesCard";

const StyledImage = styled("img")({
  width: "197.86px",
  height: "189.38px",
  objectFit: "cover",
  marginBottom: 10,
  display: "block",
});

const Dashboard: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [error, setError] = useState("");
  const [invoiceCount, setInvoiceCount] = useState(0);
  const [orderCount, setOrderCount] = useState(0);
  const [quotationCount, setQuotationCount] = useState(0);
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isLaptopSmall = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const islargescreen = useMediaQuery(theme.breakpoints.up("xl"));
  const [activeSlide, setActiveSlide] = useState(0);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const data = [
    {
      name: "My Products",
      count: 0,
      icon: (
        <Orders
          sx={{
            color: "#7A80FF",
            width: "36px",
            height: "36px",
            marginLeft: "10%",
          }}
        />
      ),
    },
    {
      name: "My Orders",
      count: orderCount,
      icon: (
        <Products
          sx={{
            color: "#7A80FF",
            width: "36px",
            height: "36px",
            marginLeft: "10%",
          }}
        />
      ),
    },
    {
      name: "My Quotation",
      count: quotationCount,
      icon: (
        <Quotation
          sx={{
            color: "#7A80FF",
            width: "36px",
            height: "36px",
            marginLeft: "15%",
          }}
        />
      ),
    },
    {
      name: "My Invoice",
      count: invoiceCount,
      icon: (
        <Invoice
          sx={{
            color: "#7A80FF",
            width: "36px",
            height: "36px",
            marginLeft: "15%",
          }}
        />
      ),
    },
  ];

  const handleplanClick = () => {
    navigate("/plans");
  };

  useEffect(() => {
    fetchDashboardCount();
  }, []);

  const fetchDashboardCount = async () => {
    const user_id = localStorage.getItem("userId");
    const token = localStorage.getItem("token"); 
    
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(api.baseUrl, {
        query: GET_CLIENT_DASHBOARD,
        variables: {
          user_id: user_id,
        },
      },
    config
  );
      const count = response.data.data.getDashboardCounts;
      if (count) {
        setInvoiceCount(count.totalInvoiceCount);
        setOrderCount(count.totalOrderCount);
        setQuotationCount(count.totalQuotationCount);
      }
    } catch (error: any) {
      setError(error.message);
    }
  };
  return (
    <div
      style={{
        backgroundColor: "white",
        overflow: "hidden",
        minHeight: "84vh",
        padding: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "5px",
        }}
      >
        <Typography
          style={{
            fontSize: "18px",
            fontFamily: "Roboto",
            color: "#181D8C",
            fontWeight: 600,
            textAlign: "center",
            lineHeight: "21px",
          }}
        >
          {TEXT_MESSAGES.Home}
        </Typography>
      </div>
      <Box
        sx={{
          marginTop: "25px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Grid container spacing={2}>
          {data.map((item) => (
            <PheonixHomeCard
              key={item.name}
              name={item.name}
              count={item.count}
              icon={item.icon}
            />
          ))}
        </Grid>
      </Box>

      <Box
        sx={{
          height: "225px",
          border: "0.5px",
          backgroundColor: "rgba(122, 128, 255, 0.1)",
          borderRadius: "8px",
          padding: "16px",
          gap: "24px",
          marginTop: "3%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 2,
          }}
        >
          <Typography
            style={{
              fontSize: "16px",
              fontFamily: "Roboto",
              fontWeight: 500,
              color: "#212121",
              lineHeight: "19.36px",
            }}
          >
            {TEXT_MESSAGES.PRODUCT_HOME}
          </Typography>
          <img
            src={productvector}
            alt="productvector"
            style={{ marginRight: "20px", height: "32px", width: "32px" }}
          />
        </Box>
        <Typography
          style={{
            fontSize: "14px",
            fontFamily: "Roboto",
            fontWeight: 500,
            color: "#212121",
            lineHeight: "16.94px",
          }}
        >
          {TEXT_MESSAGES.PRODUCT_ACTIVE}
        </Typography>
        <Box sx={{ display: "flex", gap: 2, marginTop: "1% " }}>
          {productsData.map((item, index) => (
            <ServicesCard
              key={index}
              title={item.title}
              image={item.image}
              text={item.text}
            />
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "2%",
        }}
      >
        <Typography
          style={{
            fontSize: "16px",
            fontFamily: "Roboto",
            fontWeight: 500,
            color: "#212121",
            lineHeight: "19.36px",
          }}
        >
          {TEXT_MESSAGES.PRODUCTS_HOME_TITLE}
        </Typography>
        <Button
          variant="contained"
          style={{
            backgroundColor: "white",
            height: "25px",
            borderRadius: "8px",
            color: "#181D8C",
            fontWeight: 600,
            fontStyle: "Inter",
            fontSize: "16px",
            marginRight: "10px",
            textTransform: "none",
          }}
        >
          {TEXT_MESSAGES.view_all}
        </Button>
      </Box>
      <Box
        sx={{
          textAlignLast: "center",
          border: "0.5px",
          marginTop: "2%",
          marginBottom: "1%",
        }}
      >
        <Slider {...settings}>
          {items.map((member, index) => (
            <Box key={index} sx={{ padding: "0 5px", height: "150px" }}>
              {" "}
              <Card
                sx={{
                  display: "flex",
                  width: "160px",
                  height: "130px",
                  borderRadius: "10px",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginTop: "1%",
                }}
              >
                <CardContent
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: 600,
                      fontSize: "16px",
                      lineHeight: "19.36px",
                      color: "#181D8C",
                    }}
                  >
                    {member.name}
                  </Typography>
                  <StyledImage
                    src={member.image}
                    alt="Profile"
                    style={{
                      width: "24px",
                      height: "24px",
                      marginTop: "8%",
                    }}
                  />
                  <Typography
                    style={{
                      fontWeight: 400,
                      fontSize: "10px",
                      lineHeight: "12.1px",
                      color: "#181D8C",
                      marginTop: "5%",
                    }}
                  >
                    {member.text}
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          ))}
        </Slider>
      </Box>
    </div>
  );
};
export default Dashboard;
