export const GET_INVOICE_QUERY = `
query GetInvoicebyUser($userId: ID!, $page: Int!, $perPage: Int!, $searchQuery: String) {
  getInvoicebyUser(user_id: $userId, page: $page, perPage: $perPage, searchQuery: $searchQuery) {
    totalPages
    totalCount
    invoices {
      id
      user_id
      serial_no
      invoiceid
      invoicedate
      productOrService {
                      name
                      tax
                      amount
                      rate
                      quantity
                    }
      price
      paid_amount
      duedate
      status
      customer_name
      customer_id
      email
      description
      phone_no
      expiry_date
      invoice_no
      gstType
     due_amount
    }
  }
}
`;
export const GET_CUSTOMER_QUERY = `
query GetAddedUsers($page: Int!, $perPage: Int!, $searchQuery: String) {
  getAddedUsers(page: $page, perPage: $perPage, searchQuery: $searchQuery) {
   totalPages
        totalCount
        users {
            _id
            user_id
            user_type
            customer_no
            admin_status
            basic_info {
                firstname
                lastname
                email
                phone_number
                customer_type
                company_name
                shipping_address {
                    address_line1
                    city
                    state
                    pincode
                    country
                }
            }
                 billing_info {
                GST_type
                GST_no
                payment_terms
                currency
                business_name
            }
        }
  }
}`;

export const GET_CLIENT_DASHBOARD = `
query GetDashboardCounts($user_id: ID!) {
  getDashboardCounts(user_id: $user_id) {
    totalOrderCount
    totalQuotationCount
    totalInvoiceCount
  }
}`;

export const GET_ALL_CUSTOMERS = `
query GetAllUsers {
  getAllUsers {
           _id
        user_id
        user_type
        customer_no
        basic_info {
                firstname
                lastname
                email
                phone_number
                customer_type
                company_name
  
                shipping_address {
                    address_line1
                    city
                    state
                    pincode
                    country
                }
            }
                 billing_info {
                GST_type
                GST_no
                payment_terms
                currency
                business_name
            }
  }
}`;

export const GET_INVOICE_WITH_TYPE = `
query GetInvoicebyUserType ($customer_id: ID!, $page: Int!, $perPage: Int!, $searchQuery: String){
  getInvoicebyUserType (customer_id: $customer_id, page: $page, perPage: $perPage, searchQuery: $searchQuery){
        totalPages
        totalCount
        id
        message
        invoices {
            id
            serial_no
            user_id
            name
            invoiceid
            invoicedate
            productOrService {
                      name
                      tax
                      amount
                      rate
                      quantity
                    }
            price
            paid_amount
            due_amount
            duedate
            status
            description
            subtotal
            total
            customer_data
            paymentdate
            bal_amount
            expiry_date
            invoice_no
            customer_name
            customer_id
            gstType
            phone_no
            email
        }
  }
}`;
