import React from "react";
import logo from "../assets/logo-quotes.png";
import { ERROR_MESSAGES, pdfdata, TEXT_MESSAGES } from "../const";
import PheonixButton from "./PheonixButton";
import PheonixModalProps from "../interfaces/PheonixModalProps";

const PheonixModal: React.FC<PheonixModalProps> = ({
  quote,
  onClose,
  handleAccept,
  rejectquote,
}) => {
  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case "active":
        return "#05CD99";
      case "expired":
        return "#AAAAAA";
      case "rejected":
        return "#FD3232";
      case "accepted":
        return "#05CD31";
      default:
        return "#000000";
    }
  };

  return (
    <div
      className="modal"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "93%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 999,
      }}
    >
      <div
        className="modal-content"
        style={{
          backgroundColor: "#fff",
          width: "60%",
          height: "90%",
          margin: "auto",
          marginTop: "10px",
          padding: "20px",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          scrollbarWidth: "thin",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "-20px",
            left: "-50px",
            transform: "rotate(-40deg)",
            backgroundColor: getStatusColor(quote.status),
            color: "#fff",
            padding: "15px 15px",
            fontSize: "18px",
            width: "200px",
            zIndex: 2,
            paddingLeft: "40px",
          }}
        >
          {quote.status}
        </div>
        <span
          className="close"
          onClick={onClose}
          style={{ alignSelf: "flex-end", cursor: "pointer", fontSize: "24px" }}
        >
          &times;
        </span>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <img
            src={logo}
            alt="Quotation"
            style={{
              width: "165px",
              height: "109px",
              objectFit: "cover",
              marginTop: "0px",
              marginLeft: "10px",
            }}
          />
          <div style={{ textAlign: "right" }}>
            <h2
              style={{
                textAlign: "right",
                marginRight: "10px",
                marginTop: "0px",
                fontSize: "40px",
                marginBottom: "0px",
              }}
            >
              {TEXT_MESSAGES.QUOTATION}
            </h2>
            <p style={{ margin: "5px 0" }}>
              <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                {TEXT_MESSAGES.QUOTATION_ID}:
              </span>
              <span style={{ color: "black" }}>{quote.quotationid}</span>
            </p>
            <p style={{ margin: "5px 0" }}>
              <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                {TEXT_MESSAGES.QUOTATION_DATE}:
              </span>
              <span style={{ color: "black" }}>{quote.quotationdate}</span>
            </p>
            <p style={{ margin: "5px 0" }}>
              <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                {TEXT_MESSAGES.VALID_TILL}:
              </span>
              <span style={{ color: "black" }}>{quote.duedate}</span>
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <div
            style={{ fontSize: "16px", fontWeight: "600", marginLeft: "15px" }}
          >
            <p style={{ color: "rgba(0, 0, 0, 0.6)" }}>
              {TEXT_MESSAGES.MODAL_FROM}
            </p>
            {pdfdata.PHEONIX_ADDRESS.split("\n").map((line, index) => (
              <p
                key={index}
                style={{
                  margin: "2px 0",
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "black",
                }}
              >
                {line}
              </p>
            ))}
          </div>
          <div
            style={{
              fontSize: "16px",
              fontWeight: "600",
              marginTop: "10px",
               textAlign: "right"
            }}
          >
            <p style={{ color: "rgba(0, 0, 0, 0.6)" ,margin: "2px 0"}}>
              {TEXT_MESSAGES.MODAL_TO}
            </p>
            <p
              style={{
                margin: "2px 0",
                fontSize: "16px",
                fontWeight: "400",
                color: "black",
              }}
            >
              {quote.name}
            </p>

            <p
              style={{
                margin: "2px 0",
                fontSize: "16px",
                fontWeight: "400",
                color: "black",
              }}
            >
              {quote.address}
            </p>
          </div>
        </div>
        <table
          style={{
            width: "90%",
            marginTop: "0px",
            marginRight: "20px",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: "1px solid grey",
                  textAlign: "center",
                  padding: "10px",
                }}
              >
                {TEXT_MESSAGES.PRODUCT_OR_SERVICE}
              </th>
              <th
                style={{
                  borderBottom: "1px solid grey",
                  textAlign: "center",
                  padding: "10px",
                }}
              >
                {TEXT_MESSAGES.DESCRIPTION}
              </th>
              <th
                style={{
                  borderBottom: "1px solid grey",
                  textAlign: "center",
                  padding: "10px",
                }}
              >
                {TEXT_MESSAGES.PRICE}
              </th>
            </tr>
          </thead>
          <tbody>
            {quote.productOrService.map((product, index) => (
              <tr key={index} style={{ padding: "10px" }}>
                <td
                  style={{
                    textAlign: "center",
                    borderBottom: "1px solid grey",
                    padding: "10px",
                  }}
                >
                  {product.name}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    borderBottom: "1px solid grey",
                    padding: "10px",
                  }}
                >
                  {quote.description}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    borderBottom: "1px solid grey",
                    padding: "10px",
                  }}
                >
                  {product.amount}
                </td>
              </tr>
            ))}

            <tr>
              <td></td>
              <td
                style={{
                  textAlign: "center",
                  padding: "10px",
                }}
              >
                {TEXT_MESSAGES.TOTAL}
              </td>
              <td
                style={{
                  textAlign: "center",
                  padding: "10px",
                }}
              >
                {quote.total}
              </td>
            </tr>
          </tbody>
        </table>
        {quote.status.toLowerCase() === "active" && (
          <div
            style={{
              marginTop: "80px",
              display: "flex",
              position: "relative",
              justifyContent: "flex-end",
            }}
          >
            <PheonixButton
              style={{
                marginRight: "10px",
                color: "white",
                backgroundColor: "#1A504C",
              }}
              onClick={() => handleAccept(quote)}
              label={TEXT_MESSAGES.ACCEPT}
            ></PheonixButton>
            <PheonixButton
              style={{
                marginRight: "10px",
                color: "white",
                backgroundColor: "#D32F2F",
              }}
              onClick={() => rejectquote(quote)}
              label={TEXT_MESSAGES.REJECT}
            ></PheonixButton>
          </div>
        )}
      </div>
    </div>
  );
};
export default PheonixModal;
