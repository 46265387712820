import React, { useState, useEffect,useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Popover from '@mui/material/Popover';
import PheonixPopover from '../Components/PheonixPopover';
import Typography from '@mui/material/Typography';
import { Box, margin } from '@mui/system';
import user from '../assets/pheonixprofile.png';
import newnotify from '../assets/newnotify.png';
import service from '../assets/serviceicon.png'
import LogoutIcon from '@mui/icons-material/Logout';
import LogoutModal from "../Components/LogoutModal";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { api } from '../api';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useSignUpContext } from '../Contexts/SignUpContext';
import PersonIcon from '@mui/icons-material/Person';
import PheonixPaper from '../Components/PheonixPaper';
import PheonixMenu from '../Components/PheonixMenu';
import MenuItem from '@mui/material/MenuItem';
import { useSession } from '../Contexts/SessionContext';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import TuneIcon from '@mui/icons-material/Tune';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { AppBlockingOutlined, AttachMoneyOutlined } from '@mui/icons-material';
import AppsIcon from '@mui/icons-material/Apps';
import { TEXT_MESSAGES } from "../const"
import ProductList from './Products';
import Profilepage from './Profilepage';
export default function Header() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [userData, setUserData] = useState<any>([]);
  const [userEmail, setUserEmail] = useState<any>([]);
  const [notifyopen, setnotifyopen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { id } = useSignUpContext();
  const { isLoggedIn, logout } = useSession();
  const { emailId } = useSignUpContext();
  const navigate = useNavigate();
  const notificationRef = useRef<HTMLDivElement>(null);
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleNotification = () => {
    setnotifyopen(!notifyopen);
  }
  const handleProducts = () => {
    navigate('/ProductList')
  }
  const handleClose = () => {
    setnotifyopen(false)
    setAnchorEl(null);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (notificationRef.current && !notificationRef.current.contains(event.target as Node)) {
        setnotifyopen(false);
      }
    };

    if (notifyopen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [notifyopen]);
  useEffect(() => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token"); 
    
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const fetchUserData = async () => {
      try {
        const response = await axios.post(api.baseUrl, {
          query: `
            query GetUserById($userId: ID!) {
              getUserById(id: $userId) {
                basic_info  {
                  firstname
                  lastname
                  email
                  
                }
              }
            }
          `,
          variables: {
            userId: userId
          }
        },
      config
    );
        setUserData(response.data.data.getUserById.basic_info.firstname);
        setUserEmail(response.data.data.getUserById.basic_info.email);
        setLoading(false);
      } catch (error: any) {
        setError(error.message);
        setLoading(false);
      }
    };
    if (userId) {
      fetchUserData();
    }
  }, []);
  const handleLogoutClick = () => {
    setLogoutModalOpen(true);
  }
  const confirmLogout = () => {
    navigate('/', { replace: true })
    logout();
    setLogoutModalOpen(false);
    localStorage.removeItem("userId");
  };
  const open = Boolean(anchorEl);
  const id1: string = open ? 'simple-popover' : '';
  const [menuItems, setMenuItems] = useState<any[]>([]);
  useEffect(() => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token"); 
    
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const fetchMenuItems = async () => {
      try {
        const response = await axios.post(api.baseUrl,
          {
            query: `
            query GetAllNotifications($customerId: ID) {
              getAllNotifications(customerId: $customerId) {
                id
                message
                timestamp
              }
            }
          `,
          variables: {
            customerId: userId
          }
        },
      config
    );

        const notifications = response.data.data.getAllNotifications;
        setMenuItems(notifications);
        setLoading(false);
      } catch (error) {
        console.error(TEXT_MESSAGES.ERR_MENUITEMS, error);
        setLoading(false);
      }
    };
    fetchMenuItems()
  }, []);
  const renderNotificationIcon = (message: string) => {
    if (message.includes(TEXT_MESSAGES.NEW_ORDER1)) {
      return <ListItemIcon><ListAltOutlinedIcon /></ListItemIcon>;
    } else if (message.includes(TEXT_MESSAGES.NEW_QUOTATION1)) {
      return <ListItemIcon><RequestQuoteOutlinedIcon /></ListItemIcon>;
    } else if (message.includes(TEXT_MESSAGES.NEW_PAYMENT1)) {
      return <ListItemIcon><AttachMoneyOutlined /></ListItemIcon>;
    } else {
      return <ListItemIcon><ListAltOutlinedIcon /></ListItemIcon>;
    }
  };
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', marginRight: '25px', marginBottom: '7px', cursor: 'pointer' }}
        onClick={handleProducts}>
        <TuneIcon style={{ width: '31px', height: '31px',color: "#032C43" }} /></div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', marginRight: '25px', marginBottom: '8px', cursor: 'pointer' }}
        onClick={handleNotification}>
        <NotificationsNoneIcon style={{ width: '30px', height: '30px' }} /></div>
      <div style={{
        display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', width: '210px', height: '44px', borderRadius: '35px',
        border: '1px solid', borderColor: '#00000033', marginTop: '10px', marginRight: '60px', cursor: 'pointer',backgroundColor:'#EAEBFF'
      }}
        onClick={handleClick}>
       {userData && <Typography style={{ fontFamily: 'Roboto', fontSize: '18px', flex: 5, fontWeight: '500', lineHeight: '17px', letterSpacing: '0em', textAlign: 'center' }}>Hi, {userData}!</Typography>}
       <div style={{backgroundColor:'#181D8C',borderRadius:'64px',height:'32px',width:'32px',alignItems:'center',display:'flex',justifyContent:'center',marginRight:'5px'}}>
        <img src={user} alt="Your Alt Text"
          aria-describedby={id}
          style={{
            width: '16px',
            height: '16px',
          }}
        />
        </div>
      </div>
      <PheonixPaper elevation={8}>
        <PheonixMenu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}>
          <MenuItem style={{ borderBottom: '0.1px solid lightgrey' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginBottom: '6px' }}>{userData}</Typography>
              <div ><Typography>{userEmail}</Typography></div>
            </div></MenuItem>
          <MenuItem  component={Link} to="/profile" onClick={handleClose}><div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <PersonIcon sx={{ color: 'black' }} /><Typography style={{ marginLeft: '5px' }}>{TEXT_MESSAGES.PROFILE}</Typography></div></MenuItem>
          <MenuItem >        <div onClick={handleLogoutClick} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <LogoutIcon sx={{ color: 'black' }} /><Typography style={{ marginLeft: '5px' }}>{TEXT_MESSAGES.SIGNOUT}</Typography></div></MenuItem>
        </PheonixMenu>
      </PheonixPaper>
      {notifyopen && (<div 
        ref={notificationRef}
        style={{
        position: 'absolute',
        top: '75px',
        right: '60px',
        width: '419px',
        height: '479px',
        backgroundColor: '#ffffff',
        borderRadius: '10px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        overflowY: 'auto'
      }}>
        <Typography gutterBottom style={{ fontSize: '16px', fontWeight: '600', marginBottom: '10px', marginTop: '20px', marginLeft: '20px', alignContent: 'Left' }}>Notifications</Typography>
        <List>
          {menuItems.map((item: any, index: number) => (
            <ListItem key={index} style={{
              display: 'flex',
              padding: '10px'
            }}>
              {renderNotificationIcon(item.message)}
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <div>
                  <ListItemText
                    primary={item.message}
                    primaryTypographyProps={{
                      style: { fontSize: '16px', fontWeight: '700', textAlign: 'left' }
                    }}
                  />
                  <Typography
                    variant="body2"
                    style={{ fontSize: '14px', fontWeight: 400, textAlign: 'left' }}
                  >
                    {item.timestamp}
                  </Typography>
                </div>
                <FiberManualRecordIcon style={{ color: '#D32F2F', width: '10px', height: '10px' }} />
              </div>
            </ListItem>
          ))}
        </List>
      </div>)}
      <LogoutModal
        open={logoutModalOpen}
        onClose={() => setLogoutModalOpen(false)}
        onConfirm={confirmLogout}
        
      />
    </div>
  );
}