import React from "react";
import { Box, Typography, Button } from "@mui/material";
import {
  Grid,
  useMediaQuery,
  useTheme,
  styled,
  Card,
  CardContent,
} from "@mui/material";
import productvector from "../assets/productvector.png";
import { TEXT_MESSAGES } from "../const";
import { firstBoxMockData, secondBoxMockData } from "./productsmockdata";
import LiveHelpOutlinedIcon from "@mui/icons-material/LiveHelpOutlined";
import Card1 from "./Productscard";
import Questionicon from "../assets/Questionicon.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const StyledImage = styled("img")({
  width: "197.86px",
  height: "189.38px",
  objectFit: "cover",
  marginBottom: 10,
  display: "block",
});
const Products: React.FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Typography
        style={{
          fontSize: "18px",
          fontFamily: "Roboto",
          color: "#181D8C",
          fontWeight: 600,
          lineHeight: "21px",
          marginTop: "20px",
          marginLeft: "40px",
        }}
      >
        {TEXT_MESSAGES.PRODUCTS_TITLE}
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", padding: "35px" }}>
        <Box
          sx={{
            width: "1207px",
            height: "350px",
            backgroundColor: "#F8F8FE",
            border: "0.5px solid #7A80FF",
            borderRadius: "8px",
            padding: 2,
            marginBottom: "40px",
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 2,
            }}
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontWeight: 600,
                fontSize: "16px",
                color: "#181D8C",
                marginBottom: "15px",
              }}
            >
              {TEXT_MESSAGES.PRODUCT_TITLE}
            </Typography>
            <img
              src={productvector}
              alt="productvector"
              style={{ marginRight: "20px", width: "32px", height: "32px" }}
            />
          </Box>
          <Box sx={{ display: "flex", gap: 2 }}>
            {firstBoxMockData.map((data, index) => (
              <Card1
                key={index}
                type="custom"
                image={data.image}
                title={data.title}
                planType={data.planType}
                date={data.date}
                bulletPoints={data.bulletPoints}
                buttonText={data.buttonText}
              />
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            width: "1207px",
            height: "286px",
            backgroundColor: "#F8F8FE",
            padding: 2,
            boxSizing: "border-box",
            border: "0.5px solid #7A80FF",
            borderRadius: "8px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 2,
            }}
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontWeight: 600,
                fontSize: "16px",
                color: "#181D8C",
              }}
            >
              {TEXT_MESSAGES.service_title}
            </Typography>
            <Button
              variant="contained"
              style={{
                backgroundColor: "white",
                height: "25px",
                borderRadius: "8px",
                color: "#181D8C",
                fontWeight: 600,
                fontFamily: "Roboto",
                fontSize: "16px",
                marginRight: "10px",
                textTransform: "none",
              }}
            >
              {TEXT_MESSAGES.view_all}
            </Button>
          </Box>

          <Slider {...settings}>
            {secondBoxMockData.map((data, index) => (
              <Box sx={{ display: "flex", height: "150px" }}>
                <Card
                  key={index}
                  sx={{
                    width: "160px",
                    height: "150px",
                    borderRadius: "16px",
                    backgroundColor: "#F8F8FE",
                  }}
                >
                  <CardContent
                    style={{
                      backgroundColor: "#E9E9E9",
                      width: "160px",
                      height: "130px",
                    }}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: 600,
                        fontSize: "16px",
                        color: "#181D8C",
                        marginBottom: "15px",
                      }}
                    >
                      {data.title}
                    </Typography>
                    <img
                      src={data.image}
                      alt={data.title}
                      style={{ marginLeft: "2px" }}
                    />
                  </CardContent>
                </Card>
              </Box>
            ))}
          </Slider>
        </Box>
        <div style={{ alignContent: "end" }}>
          <img
            src={Questionicon}
            style={{
              float: "inline-end",
              marginTop: "-11px",
              marginRight: "-10px",
            }}
          />
        </div>
      </Box>
    </div>
  );
};
export default Products;
