import React, { useState } from 'react';
import Login from './Pages/Login';
import { Routes, Route } from 'react-router-dom';

const App = () => {
  return (
    <div>
      <Login />
    </div>
  );
};
export default App;