export const ADD_INVOICE_MUTATION = `
  mutation AddInvoice($user_id: ID!, 
    $status: String!, 
    $productOrService: [ProductOrServiceInput!]!,
    $price: String,
    $paid_amount: String!,
  
    $email: String!, 
    $phone_no: String, 
    $customer_name: String,
    $customer_id: String!,
    $duedate:String!
    $invoicedate:String!
    $expiry_date:String!
    $description: String!
    ) {
    addInvoice(
      user_id: $user_id, 
      status: $status, 
      productOrService: $productOrService,
      price : $price,
      paid_amount: $paid_amount, 
      email: $email, 
      phone_no: $phone_no, 
      customer_name: $customer_name
      customer_id: $customer_id
      invoicedate: $invoicedate
      duedate: $duedate
      expiry_date: $expiry_date
      description: $description
      ) {
      id
      serial_no
      user_id
      invoiceid
      invoicedate
      productOrService {
        name
        tax
        amount
        rate
        quantity
      }
      price
      paid_amount
      duedate
      status
      expiry_date
      invoice_no
      customer_name
      customer_id
      gstType
      description
      phone_no
      email
    }
  }
`;
export const EDIT_INVOICE_MUTATION = `
  mutation EditInvoice($user_id: ID!,$_id: ID!, $status: String!, $productOrService: [ProductOrServiceInput!]!,$price: String!,$paid_amount: String!, 
    $duedate: String!, $expiry_date: String!, $gstType: String!, $email: String!, $phone_no: String, $customer_name: String!,$customer_id: String!,$description: String!) {
    editInvoice(user_id: $user_id,_id: $_id, status: $status, productOrService: $productOrService,duedate: $duedate,price : $price,paid_amount: $paid_amount, expiry_date: $expiry_date,
      gstType: $gstType,email: $email, phone_no: $phone_no, customer_name: $customer_name, customer_id: $customer_id,description:$description) {
      id
      serial_no
      user_id
      invoiceid
      invoicedate
      productOrService {
        name
        tax
        amount
        rate
        quantity
      }
      price
      paid_amount
      description
      duedate
      status
      expiry_date
      invoice_no
      customer_name
       customer_id
      gstType
      phone_no
      email
    }
  }
`;
export const ADD_CUSTOMER_MUTATION = `
  mutation AddUser(
    $user_id: ID, 
    $company_name: String, 
    $phone_number: String,
    $email: String!,
    $firstname: String,
    $GST_no: String, 
    $address_line1: String,
    $city: String,
    $pincode: String,
    $state: String,
    $country: String,
    $mail_status:Boolean,
    $admin_status: Boolean
  ) {
    addUser(
      user_id: $user_id, 
      company_name: $company_name, 
      phone_number: $phone_number,
      email: $email,
      firstname: $firstname, 
      GST_no: $GST_no, 
      address_line1: $address_line1,
      city: $city,
      pincode: $pincode,
      state: $state,
      country: $country,
      mail_status:$mail_status,
      admin_status: $admin_status
    ) {
      success
      message
 }
  }
`;

export const EDIT_CUSTOMER_MUTATION = `
  mutation EditUser(
    $_id: ID!,
    $user_id: ID!, 
    $company_name: String, 
    $phone_number: String,
    $email: String!,
    $firstname: String,
    $GST_no: String!, 
    $address_line1: String,
    $city: String,
    $pincode: String,
    $state: String,
    $country: String,
    $mail_status:Boolean,
    $admin_status: Boolean
  ) {
    editUser(
      _id:$_id,
       user_id: $user_id, 
      company_name: $company_name, 
      phone_number: $phone_number,
      email: $email,
      firstname: $firstname, 
      GST_no: $GST_no, 
      address_line1: $address_line1,
      city: $city,
      pincode: $pincode,
      state: $state,
      country: $country,
      mail_status:$mail_status,
      admin_status: $admin_status
    ) {
      success
        message
  }
}
`;

export const DELETE_CUSTOMER = `
  mutation DeleteUserById($id: ID!) {
    deleteUserById(id: $id) {
        success
        message
    }
  }
`;
export const DELETE_INVOICE = `
  mutation DeleteInvoice($id: ID!) {
    deleteInvoice(_id: $id) {
        success
        message
    }
  }
`;
export const DELETE_ORDER = `
  mutation DeleteOrder($id: ID!) {
    deleteOrder(_id: $id) {
        success
        message
    }
  }
`;
export const DELETE_QUOTATION = `
  mutation DeleteQuotation($id: ID!) {
    deleteQuotation(_id: $id) {
        success
        message
    }
  }
`;

export const EDIT_ORDER_MUTATION = `
  mutation EditOrder(
    $_id: ID!,
    $user_id: ID!, 
    $company_name: String!, 
    $customer_name: String!, 
    $customerId: String!,
    $contact_no: String!, 
    $contact_email: String!, 
    $productOrService: [String]!, 
    $plan: String!,
    $order_details: String!
    $renewal: String
    $actual_amount: String
    $proposed_amount: String
  ) {
    editOrder(
      _id:$_id,
      user_id: $user_id, 
      company_name: $company_name, 
      customer_name: $customer_name, 
      customerId: $customerId,
      contact_no: $contact_no, 
      contact_email: $contact_email, 
      productOrService: $productOrService, 
      plan: $plan,
      order_details: $order_details,
      renewal:$renewal
      actual_amount:$actual_amount
      proposed_amount:$proposed_amount
    ) {
            id
            serial_no
            user_id
            name
            orderid
            status
            prodstatus
            price
            renewal
            orderdate
            order_no
            company_name
            customer_name
            customerId
            contact_no
            contact_email
            productOrService_id
            productOrService
            plan_id
            plan
            order_details
            proposed_amount
            actual_amount
 }
  }
`;
