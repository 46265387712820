import React, { useState, ReactNode } from 'react';
import Sidebar from './SideBar';
import AppBar from '../Components/PheonixAppBar';
import { Box } from '@mui/system';
import LayoutProps from "../interfaces/LayoutProps";
import PheonixFooter from '../Components/PheonixFooter';

const Layout: React.FC<LayoutProps> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const handleToggleSidebar = () => {
    setIsOpen(!isOpen);
  };
 return (
    <div style={{ flexGrow: 1 ,overflow:'hidden'}}>
      <AppBar title={title} isSidebarOpen={isOpen} />
      <div style={{ display: 'flex' }}>
        <Sidebar isOpen={isOpen} onToggle={handleToggleSidebar} />
        <div style={{ flexGrow: 1, backgroundColor: 'white',overflow:'hidden'}}>
          {children}
        </div>
      </div>
      <PheonixFooter style={{left: isOpen ? "300px" : "150px"}}/>
    </div>
  );
};
export default Layout;