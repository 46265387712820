import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Typography,
  FormControl,
  InputLabel,
  Box,
  Button,
  TextField,
  IconButton,
} from "@mui/material";
import Header from "./Header";
import { TEXT_MESSAGES } from "../const";
import PheonixTextField from "../Components/PheonixTextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import SnackBar from "../Components/PheonixSnackBar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import share from "../assets/share.png";
import dayjs from "dayjs";
import { api } from "../api";
import {
  ADD_INVOICE_MUTATION,
  EDIT_INVOICE_MUTATION,
} from "../graphql/mutation";
import { GET_ALL_CUSTOMERS } from "../graphql/query";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import InvoiceData from "../interfaces/PheonixInvoice";
import InvoiceTable from "../Components/InvoiceTable";
import { RowData } from "../interfaces/PheonixProductTableprops";
const initialInvoiceData: InvoiceData = {
  "Invoice Date": "",
  "Expiry Date": "",
  "Due Date": "",
  "Total Amount": "",
  "Paid Amount": "",
  "Customer Name": "",
  "Customer Notes": "",
  "Phone Number": "",
  Email: "",
  "Customer Id": "",
  "GST Type": "",
};
interface CustomerOption {
  id: string;
  name: string;
  email?: string;
  number?: string;
  gst_no?: string;
  address?: string;
  customerNo?:string;
}
interface ProductOrService {
  name: string | null;
  tax: string | null;
  amount: string | null;
  rate: string | null;
  quantity: string | null;
}
const customer: CustomerOption = {
  id: "",
  name: "",
  email: "",
  number: "",
  gst_no: "",
  address: "",
};
const NewInvoice: React.FC = () => {
  const [expiryDate, setExpiryDate] = useState<Date | null>(null);
  const [quotationDate, setQuotationDate] = useState<Date | null>(null);
  const [dueDate, setDueDate] = useState<Date | null>(null);
  const [status, setStatus] = React.useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [succMessage, setSuccMessage] = useState("");
  const [invoiceData, setInvoicesData] =
    useState<InvoiceData>(initialInvoiceData);
  const [customerOptions, setCustomerOptions] = useState<CustomerOption[]>([
    customer,
  ]);
  const [fieldErrors, setFieldErrors] =
    useState<InvoiceData>(initialInvoiceData);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const editInvoice = location.state?.row || null;
  const [rows, setRows] = useState<RowData[]>([
    {
      "Product Details": "",
      Quantity: "",
      Rate: "",
      Tax: "",
      Amount: "",
    },
  ]);
  type FieldErrors = {
    [index: string]: {
      [fieldName: string]: string;
    };
  };
  const [tableFieldErrors, setTableFieldErrors] = useState<FieldErrors>({});
  const [loggedInUser, setLoggedInUser] = useState<string>("");
  const [buttonDisable, setButtonDisable] = useState(false);
  const [confirmDisable, setConfirmDisable] = useState(false);

  useEffect(() => {
    const usertype = localStorage.getItem("usertype");
    if (usertype !== null) {
      setLoggedInUser(usertype);
    }
  }, []);
  const handleChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };

  const areAllFieldsEmpty = (data: InvoiceData) => {
    return Object.values(data).every((value) => value !== "" && value !== null);
  };
  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    const token = localStorage.getItem("token"); 
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(api.baseUrl, {
        query: GET_ALL_CUSTOMERS,
      }, config);
      const customers = response.data.data.getAllUsers;
      const customersClient = customers.filter(
        (customer: any) => customer.user_type === "2"
      );
      if (customers) {
        const customerOptions = customersClient.map(
          (customer: {
            _id: string;
            customer_no:string;
            basic_info: {
              firstname: string;
              email: string;
              phone_number: string;
              shipping_address: { address_line1: string };
            };
            billing_info: { GST_no: string };
          }) => ({
            id: customer._id,
            name: customer.basic_info.firstname,
            email: customer.basic_info.email,
            number: customer.basic_info.phone_number,
            gst_no: customer.billing_info.GST_no,
            address: customer.basic_info.shipping_address.address_line1,
            customerNo:customer.customer_no
          })
        );
        setCustomerOptions(customerOptions);
      }
    } catch (error: any) {
      setError(error.message);
    }
  };
  const mapRowsToProductOrService = (rows: RowData[]): ProductOrService[] => {
    return rows.map((row) => ({
      name: row["Product Details"] || null,
      tax: row["Tax"] || null,
      amount: row["Amount"] || null,
      rate: row["Rate"] || null,
      quantity: row["Quantity"] || null,
    }));
  };

  const checkForErrors = (): boolean => {
    for (const rowIndex in tableFieldErrors) {
      for (const field in tableFieldErrors[rowIndex]) {
        if (tableFieldErrors[rowIndex][field]) {
          return true;
        }
      }
    }
    return false;
  };

  const areAllRowsComplete = (rows: RowData[]): boolean => {
    return rows.every((row) => {
      return Object.values(row).every((value) => value !== "");
    });
  };

  useEffect(() => {
    const hasErrors = Object.values(fieldErrors).some((error) => !!error);
    const hasTableErrors = checkForErrors();
    setConfirmDisable(hasErrors);
    setConfirmDisable(hasTableErrors);
  }, [fieldErrors, tableFieldErrors]);

  useEffect(() => {
    const isAllRows = areAllRowsComplete(rows);
    if (isAllRows) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  }, [rows]);
  useEffect(() => {
    const isButtonDisabled = !areAllFieldsEmpty(invoiceData);

    if (isButtonDisabled) {
      setButtonDisable(true);
    } else {
      setButtonDisable(false);
    }
  }, [invoiceData]);

  const handleClose = () => {
    setModalOpen(false);
  };
  useEffect(() => {
    if (editInvoice) {
      setInvoicesData({
        "Invoice Date": editInvoice.invoicedate,
        "Expiry Date": editInvoice.expiry_date,
        "Due Date": editInvoice.duedate,
        "Customer Name": editInvoice.customer_name,
        "Customer Id": editInvoice.customer_id,
        "Phone Number": editInvoice.phone_no,
        Email: editInvoice.email,
        "Total Amount": editInvoice.price,
        "Paid Amount": editInvoice.paid_amount,
        "Customer Notes": editInvoice.description,
        "GST Type": editInvoice.gstType,
      });
      const customer = customerOptions.find(
        (customer) => customer.name === editInvoice.name
      );
      if (customer) {
        setInvoicesData((prevData) => ({
          ...prevData,
          "Customer ID": customer.id,
        }));
      }
      setRows(mapProductOrServiceToRows(editInvoice.productOrService));
    }
    setStatus(editInvoice?.status || "");
  }, [editInvoice]);
  const mapProductOrServiceToRows = (
    productOrService: ProductOrService[]
  ): RowData[] => {
    return productOrService.map((service) => ({
      "Product Details": service.name || "",
      Quantity: service.quantity || "",
      Rate: service.rate || "",
      Tax: service.tax || "",
      Amount: service.amount || "",
    }));
  };

  const customCloseIcon = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
      style={{ justifyContent: "end", width: "200px" }}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  const handleSave = async () => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token"); 
    const productOrService = mapRowsToProductOrService(rows);
    const query = ADD_INVOICE_MUTATION;
    const variables = {
      user_id: userId,
      invoicedate: invoiceData["Invoice Date"],
      duedate: invoiceData["Due Date"],
      expiry_date: invoiceData["Expiry Date"],
      productOrService: productOrService,
      price: typeof invoiceData["Total Amount"] === 'string' ? invoiceData["Total Amount"] : String(invoiceData["Total Amount"]),
      paid_amount: invoiceData["Paid Amount"],
      status: status,
      gstType: invoiceData["GST Type"],
      customer_name: invoiceData["Customer Name"],
      customer_id: invoiceData["Customer Id"],
      phone_no: invoiceData["Phone Number"],
      email: invoiceData["Email"],
      description: invoiceData["Customer Notes"],
    };
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(api.baseUrl, {
        query,
        variables,
      }, config );
      const { addInvoice } = response.data.data;
      if (addInvoice && addInvoice.id) {
        setSuccMessage(TEXT_MESSAGES.INVOICE_SUCCESS);
      } else {
        setSuccMessage(TEXT_MESSAGES.Invoice_FAILURE);
      }
      setModalOpen(true);
      setTimeout(() => {
        setModalOpen(false);
        navigate("/invoice");
      }, 3000);
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = async () => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token"); 

    const productOrService = mapRowsToProductOrService(rows);
    const query = EDIT_INVOICE_MUTATION;
    const variables = {
      _id: editInvoice.id,
      user_id: userId,
      invoicedate: invoiceData["Invoice Date"],
      duedate: invoiceData["Due Date"],
      expiry_date: invoiceData["Expiry Date"],
      productOrService: productOrService,
      price: typeof invoiceData["Total Amount"] === 'string' ? invoiceData["Total Amount"] : String(invoiceData["Total Amount"]),
      paid_amount: invoiceData["Paid Amount"],
      status: status,
      gstType: invoiceData["GST Type"],
      customer_name: invoiceData["Customer Name"],
      customer_id: invoiceData["Customer Id"],
      phone_no: invoiceData["Phone Number"],
      email: invoiceData["Email"],
      description: invoiceData["Customer Notes"],
    };
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(api.baseUrl, {
        query,
        variables,
      }, config );
      const { editInvoice } = response.data.data;
      if (editInvoice && editInvoice.id) {
        setSuccMessage(TEXT_MESSAGES.INVOICE_UPDATE);
      } else {
        setSuccMessage(TEXT_MESSAGES.Invoice_UPDATE_FAILURE);
      }
      setModalOpen(true);
      setTimeout(() => {
        setModalOpen(false);
        navigate("/invoice");
      }, 3000);
    } catch (error) {
      console.error(error);
    }
  };
  const handleCancel = () => {
    navigate("/invoice");
  };

  return (
    <Box
      style={{
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "1%",
        }}
      >
        <Typography
          style={{
            fontSize: "24px",
            fontFamily: "Roboto",
            color: "#181D8C",
            fontWeight: 600,
            marginLeft: "1%",
          }}
        >
          {editInvoice
            ? TEXT_MESSAGES.EDIT_iNVOICE
            : TEXT_MESSAGES.CREATE_INVOICE}
        </Typography>
      </Box>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "15px",
          border: "0.5px solid #B7BAFF",
          padding: "5px",
          width: "95%",
          marginLeft: "1%",
          borderRadius: "4px",
          height: "62vh",
          overflowY: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            margin: "5px",
            padding: "5px",
            marginTop: "0px",
            marginLeft: "-5px",
            marginRight: "0%",
            width: "95%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              marginTop: "1%",
              marginLeft: "1%",
            }}
          ></Box>
          <InvoiceTable
            InvoiceData1={invoiceData}
            setInvoiceData1={setInvoicesData}
            status1={status}
            setStatus1={setStatus}
            QuotationDate1={quotationDate}
            setQuotationDate1={setQuotationDate}
            DueDate1={dueDate}
            setDueDate1={setDueDate}
            ExpiryDate1={expiryDate}
            setExpiryDate1={setExpiryDate}
            fieldErrors={fieldErrors}
            setFieldErrors={setFieldErrors}
            customerOptions={customerOptions}
            setCustomerOptions={setCustomerOptions}
            rows={rows}
            setRows={setRows}
            tableFieldErrors={tableFieldErrors}
            setTableFieldErrors={setTableFieldErrors}
          />
        </Box>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          alignSelf: "flex-end",
          marginTop: "auto",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            height: "0.5px",
            width: "100%",
            backgroundColor: "#CBC2E9",
          }}
        ></div>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <Button
            onClick={handleCancel}
            style={{
              color: "grey",
              backgroundColor: "white",
              border: "1px solid #1C1B1F",
              width: "125px",
              height: "36px",
              borderRadius: "5px",
              marginLeft: "2%",
            }}
          >
            {TEXT_MESSAGES.CANCEL}
          </Button>
          <Button
            onClick={editInvoice ? handleEdit : handleSave}
            disabled={buttonDisable || confirmDisable}
            sx={{
              color: buttonDisable || confirmDisable ? "#000000" : "white",
              border: "1px solid",
              backgroundColor:
                buttonDisable || confirmDisable ? "lightgrey" : "#181D8C",
              width: "125px",
              height: "36px",
              borderRadius: "5px",
              marginRight: "2%",
              "&:hover": { backgroundColor: "#181D8C" },
              "&:active": { backgroundColor: "#181D8C" },
            }}
          >
            {editInvoice ? TEXT_MESSAGES.UPDATE_TXT : TEXT_MESSAGES.SAVE}
          </Button>
        </Box>
      </div>
      <SnackBar
        open={modalOpen}
        onClose={handleClose}
        message={succMessage}
        vertical="top"
        horizontal="center"
        customAction={customCloseIcon}
      />
    </Box>
  );
};
export default NewInvoice;
