import React from "react";
import { Card, CardContent, Box, Typography } from "@mui/material";
import PheonixHomeCardProps from "../interfaces/PheonixHomeCard";

const PheonixHomeCard: React.FC<PheonixHomeCardProps> = ({
  name,
  count,
  icon,
}) => {
  return (
    <Card
      style={{
        display: "flex",
        width: "270px",
        height: "120px",
        backgroundColor: 'rgba(106, 95, 190, 0.1)',
        borderRadius: "10px",
        boxShadow:
          "0px 5px 5px -3px #00000033, 0px 8px 10px 1px #00000024, 0px 3px 14px 2px #0000001F",
        margin: "auto",
      }}
    >
      <CardContent style={{ display: "flex", flex: 1 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flex: 1,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>

            <Typography
              sx={{
                fontFamily: "roboto",
                fontSize: "24px",
                fontWeight: "600",
                lineHeight: "29.05px",
                color: "#212121",
                marginTop: "5%",
              }}
            >
              {count}
            </Typography>
            <Typography
              sx={{
                fontFamily: "roboto",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "19.36px",
                color: "#032C43",
                marginTop: "25%",
              }}
            >
              {name}
            </Typography>
          </Box>
          <Box
            sx={{ display: "flex", marginRight: "10%" }}
          >
            <Box
              sx={{
                display: "flex",
                width: "50px",
                height: "50px",
              }}
            >
              {icon}
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default PheonixHomeCard;
