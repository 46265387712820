import React from 'react';
import { Card as MuiCard, CardContent, Box, Typography, Button, SxProps, Theme } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { CardProps1 } from '../Components/productsCardProps';

const Productscard: React.FC<CardProps1> = ({ type, image, title, planType, date, bulletPoints, buttonText, sx }) => {
    if (type === 'custom') {
        return (
            <MuiCard sx={{ width: '450px', height: '251px', borderRadius: '16px', ...sx }}>
                <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                        <img src={image} alt={title} />
                        <Typography variant="h6">{title}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: "10px", marginBottom: "10px" }}>
                        <Button variant="contained" style={{
                            backgroundColor: "#7A80FF", color: "#FFFFFF", textTransform: "none",
                            borderRadius: "8px", fontSize: "16px"
                        }}>{planType}</Button>
                        <Typography variant="body2" sx={{ marginTop: 1, marginBottom: 2 }}>
                            {date}
                        </Typography>
                    </Box>
                    <Box>
                        {bulletPoints && bulletPoints.map((point, index) => (
                            <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                                <CircleIcon sx={{ marginRight: 1,fontSize:"6px" }} />
                                <Typography variant="body2">{point}</Typography>
                            </Box>
                        ))}
                    </Box>
                    <Button variant="text" sx={{ marginTop: 1, backgroundColor: "#F8F8FE", color: "#181D8C", textTransform: "none" }}>{buttonText}</Button>
                </CardContent>
            </MuiCard>
        );
    }
    return (
        <MuiCard sx={{ ...sx }}>
            <CardContent style={{ backgroundColor: "#E9E9E9", width: "160px", height: "130px" }}>
                <Typography variant="body2" style={{ fontFamily: "inter", fontWeight: 600, fontSize: "16px", color: "#181D8C", marginBottom: "15px" }}>
                    {title}
                </Typography>
                <img src={image} alt={title} style={{ marginLeft: "2px" }} />
            </CardContent>
        </MuiCard>
    );
};
export default Productscard;