import React, { ChangeEvent, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PheonixTextField from '../Components/PheonixTextField';
import PheonixButton from '../Components/PheonixButton';
import logo from '../assets/logo.jpg';
import PheonixPaper from '../Components/PheonixPaper';
import PheonixBox from '../Components/PheonixBox';
import PheonixContainer from '../Components/PheonixContainer';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Container, styled } from '@mui/material';
import SnackBar from '../Components/PheonixSnackBar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Stack } from '@mui/material';
import axios from 'axios';
import { api } from '../api';
import { ERROR_MESSAGES, TEXT_MESSAGES } from '../const';
import { useSignUpContext } from '../Contexts/SignUpContext';

const Item = styled('div')({
  marginBottom: '20px',
  padding: '8px',
  textAlign: 'center',
});
const RequestOTP: React.FC = () => {
  const [email, setemail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessage1, setErrorMessage1] = useState('');
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [verificationMessage, setVerificationMessage] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [succMessage, setSuccMessage] = useState('')
  const { emailId, setEmailId } = useSignUpContext();
  const { id, setUserId } = useSignUpContext();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const cameFromLogin = sessionStorage.getItem('cameFromLogin');
    const cameFromVerify = sessionStorage.getItem('cameFromVerify');
    if (!cameFromLogin && !cameFromVerify) {
      navigate('/', { replace: true });
    }
  }, [navigate]);

  useEffect(() => {
    sessionStorage.removeItem('cameFromLogin');
  }, []);
  const handleemailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setemail(e.target.value);
  };
  const handleEmailFocus = () => {
    setErrorMessage1('');
    setErrorMessage('');
  };
  function isValidEmail(email: string) {
    const emailRegex = TEXT_MESSAGES.EMAIL_REG;
    return emailRegex.test(email);
  }
  const handleEmailBlur = () => {
    const emailRegex = TEXT_MESSAGES.EMAIL_REG;
    if (!emailRegex.test(email)) {
      setButtonEnabled(true);
      setErrorMessage(ERROR_MESSAGES.VALID_EMAIL);
    } else {
      setErrorMessage('');
    }
  };
  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      handlerequestotp();
    }
  };
  const handlerequestotp = async () => {
    if (!email) {
      setErrorMessage(ERROR_MESSAGES.ENTER_ALL);
      return;
    }
    if (email && !isValidEmail(email)) {
      setErrorMessage1(ERROR_MESSAGES.VALID_EMAIL);
      return;
    }
    const query = `
    mutation RequestOtp($email : String!){
        requestOtp(email : $email){
        success
        message 
        userId
        }
    }
    `;
    const variables = {
      email: email,
    };
    try {
      const response = await axios.post(api.baseUrl, {
        query,
        variables,
      });
      const { success, message, userId } = response.data.data.requestOtp;
      if (success) {
        setSuccMessage(TEXT_MESSAGES.OTP_SENT);
        setEmailId(email);
        localStorage.setItem("emailid", email);
        localStorage.setItem("userId",userId);
        setUserId(userId);
        setModalOpen(true);
        setTimeout(() => {
          sessionStorage.setItem('cameFromReq', 'true');
          navigate('/verifyotp')
          setemail('');
        }, 3000);
      } else {
        setErrorMessage(message);
      }
      setVerificationMessage(ERROR_MESSAGES.CHECK_MAIL);

    }
    catch (error) {
      console.error(ERROR_MESSAGES.ERROR, error);
      setErrorMessage(ERROR_MESSAGES.ERROR);
    }
   
  };
  const handleClose = () => {
    setModalOpen(false);
    navigate('/verifyotp');
  };
  const customCloseIcon = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose} style={{ justifyContent: 'end', width: '200px' }}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );
  useEffect(() => {
    const emailValid = isValidEmail(email);
    if (!emailValid) {
      setButtonEnabled(true);
    } else {
      setButtonEnabled(false);
    }
  }, [email]);
  return (
    <div style={{ backgroundColor: "#F7F7F7" }}>
      <PheonixBox >
        <PheonixContainer maxWidth='xs'>
          <PheonixPaper
            elevation={3}
            style={{
              padding: '20px',
              height: '400px',
              marginLeft: '10px',
              width: '400PX',
              borderRadius: '0',
            }}
          >
            <Stack spacing={2} direction="column">
              <Item>
                <img src={logo} alt="Your Alt Text" style={{ width: '150px', height: '99px' }} />
              </Item>
              <Box component="form" onKeyPress={handleKeyPress}>

                <Item>
                  <Typography variant="h6" style={{ color: '#1161A0', fontFamily: 'Roboto', marginBottom: '10px', marginTop: '-5px' }}>{TEXT_MESSAGES.FORGOT_LABEL}</Typography>
                </Item>
                <Item>
                  <PheonixTextField
                    id="outlined-error"
                    type="email"
                    value={email}
                    placeholder="Email"
                    label="Email"
                    variant="outlined"
                    onChange={handleemailChange}
                    onFocus={handleEmailFocus}
                    onBlur={handleEmailBlur}
                    error={Boolean(errorMessage)}
                    helperText={errorMessage || ''}
                    style={{ width: '350px', }}
                  />
                </Item>
                <Item>
                  <PheonixButton label={TEXT_MESSAGES.SEND_BUTTON} onClick={handlerequestotp} disabled={buttonEnabled} />
                </Item>
              </Box>
            </Stack>
          </PheonixPaper>
          <SnackBar
            open={modalOpen}
            onClose={handleClose}
            message={succMessage}
            vertical="top"
            horizontal="center"
            customAction={customCloseIcon}
          />
        </PheonixContainer>
      </PheonixBox>
    </div>
  );
};
export default RequestOTP;