import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import PheonixSideBarProps from '../interfaces/PheonixSideBarProps';

const PheonixSideBar: React.FC<PheonixSideBarProps> = ({ icon, text, to }) => {
  const location = useLocation();
return (
    <ListItem
    sx={{
      textDecoration: 'none',
      backgroundColor: location.pathname === to  ? 'white' : 'transparent',
      color: location.pathname === to ? '#181D8C' : 'inherit',
      borderTopLeftRadius: '16px',
      borderBottomLeftRadius: '16px',
      borderTopRightRadius : text? '0': '16px',
      borderBottomRightRadius: text? '0': '16px',
      '&:hover': { backgroundColor:  'white', textDecoration: 'none', color: '#181D8C'},
      fontSize: '20px',
      '& .MuiTypography-root': {
        fontSize: '16px', 
        fontWeight:'700',
      },
    }}
      component={Link}
      to={to}
    >
      <ListItemIcon sx={{  color: location.pathname === to ? '#181D8C' : 'inherit',width:'56px', height:'24px',
        
      }} >{icon}</ListItemIcon>
      <ListItemText primary={text} sx={{marginLeft:"30px"}} />
    </ListItem>
  );
};
export default PheonixSideBar;