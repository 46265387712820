import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Typography,
  FormControl,
  InputLabel,
  Box,
  Button,
  TextField,
  IconButton,
  Card,
  CardContent,
  InputAdornment
} from "@mui/material";
import Flatpickr from "react-flatpickr";
import { ERROR_MESSAGES, TEXT_MESSAGES } from "../const";
import { api } from "../api";
import axios from "axios";
import { GET_ALL_CUSTOMERS } from "../graphql/query";
import PheonixTextField from "../Components/PheonixTextField";
import PheonixProductTable from "./PheonixProductTable";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { RowData, TableColumn } from "../interfaces/PheonixProductTableprops";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import dayjs from "dayjs";
import QuoteTableProps from "../interfaces/QuoteTableProps";
import QuoteData from "../interfaces/PheonixQuotation";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const QuoteTable: React.FC<QuoteTableProps> = ({
  QuotesData,
  view,
  edit,
  setQuotesData,
  status,
  setStatus,
  currency,
  setCurrency,
  QuotationDate,
  setQuotationDate,
  ExpiryDate,
  setExpiryDate,
  fieldErrors,
  setFieldErrors,
  customerOptions,
  setCustomerOptions,
  rows,
  setRows,
  tableFieldErrors,
  setTableFieldErrors,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [totalAmount, setTotalAmount] = useState("");
  const [loggedInUser, setLoggedInUser] = useState<string>("");
const [selectedCustomer,setSelectedCustomer] =  useState<any>(null);
  const calculateAmount = (rows: any) => {
    return rows.reduce(
      (total: any, row: any) => total + (parseFloat(row.Rate || 0) * parseFloat(row.Quantity || 0)),
      0
    );
  };

  const calculateTotalAmount = (rows: any[]): string => {
    const total = rows.reduce(
        (total: number, row: any) => total + parseFloat(row.Amount || '0'),
        0
    );
    return total.toFixed(2).toString(); 
};


  useEffect(() => {
    const usertype = localStorage.getItem("usertype");
    if (usertype !== null) {
      setLoggedInUser(usertype);
    }
  }, []);

  useEffect(() => {
    const totalAmount = calculateTotalAmount(rows);
    setTotalAmount(totalAmount);
    setQuotesData((prevState) => ({
      ...prevState,
      ["Total Amount"]: totalAmount,
    }));
  }, [rows]);

  const handleChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };
  const handleCurrencyChange = (event: SelectChangeEvent) => {
    setCurrency(event.target.value);
  };

  const handleQuotationDateChange = (date: any) => {
    const formattedDate = dayjs(date).format("DD/MM/YYYY");
    setQuotesData((prevState) => ({
      ...prevState,
      ["Quotation Date"]: formattedDate,
    }));
  };
  const handleExpiryDateChange = (date: any) => {
    const formattedDate = dayjs(date).format("DD/MM/YYYY");
    setQuotesData((prevState) => ({
      ...prevState,
      ["Expiry Date"]: formattedDate,
    }));
  };


  const handleNameChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setSelectedCustomer(newValue);
    const { id, email, customerNo } = newValue;
    setQuotesData((prevQuoteData) => ({
      ...prevQuoteData,
      "Customer No" : customerNo,
      "Customer Id": id,
      Email: email,
    }));
    
  };
  

  const handleInputChange = (label: string, value: string) => {
    const newFormData: QuoteData = { ...QuotesData, [label]: value };
    switch (label) {

      case "GST Number":
        if (!value) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.GST_ERROR,
          }));
        } else if (!/^[a-zA-Z0-9]+$/.test(value)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.GST_ERR1,
          }));
        } else if (value.length !== 15) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.GST_LENGTH,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
        }
        break;

      case "Address":
        if (!value) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.ADD_ERROR,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
        }
        break;
      case "Quotation Notes":
        if (!value) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.DESC_ERROR,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
        }
        break;
      case "Customer Notes":
        if (!value) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.DESC_ERROR,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
        }
        break;
        case "Terms and Conditions":
          if (!value) {
            setFieldErrors((prevErrors) => ({
              ...prevErrors,
              [label]: ERROR_MESSAGES.DESC_ERROR,
            }));
          } else {
            setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
          }
          break;
      case "Product/Plan":
        if (!value) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.PROD_ERROR,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
        }
        break;
      case "Phone Number":
        if (!/^[0-9]*$/.test(value)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.NUMBER_ERROR,
          }));
        } else if (value.length !== 10) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.NUMBER_LENGTH,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
        }
        break;
      case "Total Amount":
        if (!/^₹?\d*\.?\d*$/.test(value)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.AMOUNT_ERROR,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
        }
        break;
        case "Discount Amount":
          if (!/^₹?\d*\.?\d*$/.test(value)) {
            setFieldErrors((prevErrors) => ({
              ...prevErrors,
              [label]: ERROR_MESSAGES.AMOUNT_ERROR,
            }));
          } else {
            setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
          }
          break;
          case "Tax Amount":
            if (!/^₹?\d*\.?\d*$/.test(value)) {
              setFieldErrors((prevErrors) => ({
                ...prevErrors,
                [label]: ERROR_MESSAGES.AMOUNT_ERROR,
              }));
            } else {
              setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
            }
            break;
      case "Email":
        if (!TEXT_MESSAGES.EMAIL_REG.test(value)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.MAIL_ERROR,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
        }
        break;
      default:
        return true;
    }

    setQuotesData(newFormData);
  };

  const handleDeleteRow = (index: number) => {
    if (rows.length > 1) {
      const newRows = [...rows];
      newRows.splice(index, 1);
      setRows(newRows);
      setTableFieldErrors((prevErrors: { [key: string]: any }) => {
        const newErrors = { ...prevErrors };
        if (newErrors[index]) {
          Object.keys(newErrors[index]).forEach((field) => {
            newErrors[index][field] = "";
          });
        }
        return newErrors;
      });
    }
  };
  const handleAddRow = () => {
    const newRow = {
      "Product Details": "",
      Quantity: "",
      Rate: "",
      Tax: "",
      Amount: "",
      "Product Plan":"",
      "Billing Cycle":"",
      "HSN Number":""
    };
    setRows([...rows, newRow]);
  };

  const handleInputChange1 = (
    index: number,
    fieldName: keyof (typeof rows)[0],
    value: string
  ) => {
    if (fieldName === "Product Details") {
      if (/[^\w\s]/.test(value)) {
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: ERROR_MESSAGES.BATCH_VAL,
          },
        }));
      } else {
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: "",
          },
        }));
      }
    } else if (fieldName === "Rate") {
      const isValidNumber = /^\d*.?\d+$/.test(value) && parseFloat(value) >= 0;
      if (!isValidNumber) {
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: ERROR_MESSAGES.MRP_VAL,
          },
        }));
      } else {
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: "",
          },
        }));
      }
    } else if (fieldName === "Quantity") {
      if (!/^\d+$/.test(value)) {
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: ERROR_MESSAGES.QUANTITY_VAL,
          },
        }));
      } else if (!value) {
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: ERROR_MESSAGES.QUANTITY_REQ,
          },
        }));
      } else {
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: "",
          },
        }));
      }
    } else if (fieldName === "Amount") {
      const isValidNumber = /^\d*.?\d+$/.test(value) && parseFloat(value) >= 0;
      if (!isValidNumber) {
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: ERROR_MESSAGES.AMT_VAL,
          },
        }));
      } else if (!value) {
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: ERROR_MESSAGES.AMT_REQ,
          },
        }));
      } else {
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: "",
          },
        }));
      }
    }else if(fieldName === "HSN Number"){
      if (!/^[0-9]*$/.test(value)){
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: ERROR_MESSAGES.HSN_VAL,
          },
        }));
      } else if (value.length > 8) {
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: ERROR_MESSAGES.HSN_ERROR,
          },
        }));
      } else if (!/^[a-zA-Z0-9]+$/.test(value)){
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: ERROR_MESSAGES.HSN_ERR1,
          },
        }));
      }else  if (!value){
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: ERROR_MESSAGES.HSN_REQ,
          },
        }));
      }else{
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: "",
          },
        }));
      }
    }

    const newRows = rows.map((row, rowIndex) => {
      if (index === rowIndex) {
        return { ...row, [fieldName]: value };
      }
      return row;
    });
    if (fieldName === "Rate" || fieldName === "Quantity") {
      const rate = parseFloat(newRows[index].Rate || "0");
      const quantity = parseFloat(newRows[index].Quantity || "0");
      const amount = (rate * quantity).toFixed(2); 
      newRows[index].Amount = amount.toString();
    }
    setRows(newRows);
  };

  const discountOptions =['1','2','3','4','5'];
  const handleDiscountChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setQuotesData((prevData) => ({
      ...prevData,
        "Discount Amount":newValue
    }));
  };

  const columns = [
    {
      field: "Product Details",
      headerName: "Product Details",
    },
    {
      field: "Product Plan",
      headerName: "Product Plan",
    },
    {
      field: "Billing Cycle",
      headerName: "Billing Cycle",
    },
    {
      field: "HSN Number",
      headerName: "HSN Number",
    },
   
    {
      field: "Quantity",
      headerName: "Quantity",
    },

    {
      field: "Rate",
      headerName: "Rate",
    },
    {
      field: "Tax",
      headerName: "Tax",
    },

    {
      field: "Amount",
      headerName: "Amount",
    },
    {
      field: "Action",
      headerName: "Action",
      render: (index: number) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton
          disabled={view && !edit}
            onClick={() => handleAddRow()}
            style={{ color: "#181D8C" }}
          >
            <AddCircleOutlineOutlinedIcon />
          </IconButton>
          <IconButton
          disabled={view  && !edit}
            onClick={() => handleDeleteRow(index)}
            style={{ color: "#D32F2F" }}
          >
            <CloseOutlinedIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <Box >
      <table style={{ width: "100%", marginTop: "1%" }}>
        <tbody>
          <tr>
            <td
              style={{
                display: "flex",
                padding: "10px",
              }}
            >
              <PheonixTextField
                id="outlined-error"
                type="text"
                disabled={view  && !edit}
                value={QuotesData["Quotation Notes"]}
                placeholder="Quotation Notes"
                label="Quotation Notes"
                error={!!fieldErrors?.["Quotation Notes"]}
                helperText={fieldErrors["Quotation Notes"]}
                name="Quotation Notes"
                variant="outlined"
                size="small"
                onChange={(e) =>
                  handleInputChange(e.target.name, e.target.value)
                }
                style={{ width: "652px" }}
              />
            </td>
            <td style={{ width: "25%" }}> </td>
            <td style={{ width: "25%" }}></td>
            <td style={{ width: "25%" }}></td>
          </tr>
          <tr>
            <td
              style={{
                display: "flex",
                padding: "10px",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Quotation Date"
                  format="DD/MM/YYYY"
                  disabled={view  && !edit}
                  onChange={handleQuotationDateChange}
                  disablePast
                  value={
                    QuotesData["Quotation Date"]
                      ? dayjs(QuotesData["Quotation Date"], "DD/MM/YYYY")
                      : null
                  }
                  sx={{
                    width: 309,
                  }}
                />
              </LocalizationProvider>
              <div style={{ marginLeft: "30px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Expiry Date"
                    format="DD/MM/YYYY"
                    disabled={view  && !edit}
                    onChange={handleExpiryDateChange}
                    disablePast
                    minDate={
                      QuotesData["Quotation Date"]
                        ? dayjs(QuotesData["Quotation Date"], "DD/MM/YYYY")
                        : dayjs()
                    }
                    value={
                      QuotesData["Expiry Date"]
                        ? dayjs(QuotesData["Expiry Date"], "DD/MM/YYYY")
                        : null
                    }
                    sx={{
                      width: 309,
                    }}
                  />
                </LocalizationProvider>
              </div>
            </td>
            <td style={{ width: "25%" }}></td>
            <td style={{ width: "25%" }}></td>
          </tr>
         
    <tr>
      <td
        style={{
          display: "flex",
          padding: "10px",
        }}
      >
        <Autocomplete
          disablePortal
          options={customerOptions}
          renderInput={(params) => (
            <TextField {...params} label="Customer Name" disabled={view  && !edit} />
          )}
          disableClearable={true}
          disabled={view && !edit}
          getOptionLabel={(option) =>
            option.name ? `${option.name} (${option.customerNo})` : option.customerNo
          }
          onChange={(event, newValue) => handleNameChange(event, newValue)}
          value={
            customerOptions.find(
              (customer) => customer.customerNo === QuotesData["Customer No"]
            ) || { id: "", customerNo: "",email:"" }
          }
          style={{ width: "309px" }}
        />
        <div style={{ marginLeft: "30px" }}>
          <FormControl sx={{ width: "309px" }}>
            <InputLabel id="demo-select-small-label">Select Currency</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={currency}
              label="Select Currency"
              onChange={handleCurrencyChange}
              disabled={view && !edit}
            >
              <MenuItem value="">
                <em>Select Currency</em>
              </MenuItem>
              <MenuItem value={"Rupees"}>Rupees</MenuItem>
              <MenuItem value={"Dollars"}>Dollars</MenuItem>
              <MenuItem value={"Europe"}>Europe</MenuItem>
            </Select>
          </FormControl>
        </div>
      </td>
    </tr>
    {selectedCustomer && (
  <tr>
    <td 
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(3, 2fr)",
        gap: "10px",
        padding: "10px",
        fontFamily: "Inter",
        color: "#181D8C",
        fontSize: "16px",
      
      }}
    >
      {selectedCustomer.number && (
        <div style={{  marginBottom:"10px"}}>
          <Typography variant="body1">
            <strong>Phone Number:</strong> {selectedCustomer.number}
          </Typography>
        </div>
      )}
      {selectedCustomer.email && (
        <div style={{  marginBottom:"10px"}}>
          <Typography variant="body1">
            <strong>Email:</strong> {selectedCustomer.email}
          </Typography>
        </div>
      )}
      {selectedCustomer.gst_no && (
        <div style={{  marginBottom:"10px"}} >
          <Typography variant="body1">
            <strong>GST No:</strong> {selectedCustomer.gst_no}
          </Typography>
        </div>
      )}
      
      {selectedCustomer.address && (
        <div style={{  marginBottom:"10px"}}>
          <Typography variant="body1">
            <strong>Address:</strong> {selectedCustomer.address}
          </Typography>
        </div>
      )}
      {selectedCustomer.city && (
        <div style={{  marginBottom:"10px"}}>
          <Typography variant="body1">
            <strong>City:</strong> {selectedCustomer.city}
          </Typography>
        </div>
      )}
      {selectedCustomer.pincode && (
        <div style={{  marginBottom:"10px"}}>
          <Typography variant="body1">
            <strong>PinCode:</strong> {selectedCustomer.pincode}
          </Typography>
        </div>
      )}
      {selectedCustomer.state && (
        <div >
          <Typography variant="body1">
            <strong>State:</strong> {selectedCustomer.state}
          </Typography>
        </div>
      )}
      {selectedCustomer.country && (
        <div >
          <Typography variant="body1">
            <strong>Country:</strong> {selectedCustomer.country}
          </Typography>
        </div>
      )}
    </td>
  </tr>
)}
          <tr>
            <td
              colSpan={2}
              style={{
                display: "flex",
                padding: "10px",
              }}
            >
              
            </td>
            <td style={{ padding: "10px" }}>
             
            </td>
            <td style={{ width: "25%" }}></td>
          </tr>
          <tr>
            <td colSpan={5}>
              <div >
                <Card >
                  <CardContent
                    style={{ backgroundColor: "lightgrey", height: "3px" }}
                  >
                    <div style={{ marginTop: "-10px" }}>Products</div>
                  </CardContent>
                  <div>
                    <PheonixProductTable
                      rows={rows}
                      columns={columns}
                      handleInputChange={handleInputChange1}
                      handleAddRow={handleAddRow}
                      handleDeleteRow={handleDeleteRow}
                      fieldErrors={tableFieldErrors}
                      view={view}
                      edit={edit}
                    />
                  </div>
                </Card>
              </div>
            </td>
          </tr>
          <tr>
            <td
              style={{
                display: "flex",
                padding: "10px",
              }}
            >
<Autocomplete
  options={discountOptions}
  disablePortal
  disableClearable={true}
  disabled={view  && !edit}
  onChange={(event, newValue) => handleDiscountChange(event, newValue)}
  value={QuotesData["Discount Amount"]}
  style={{ width: "309px" }}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Discount Amount"
      variant="outlined"
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <InputAdornment
            position="end"
            style={{
              backgroundColor: "#EAEBFF", 
              borderRadius: "4px", 
              display: "flex", 
              alignItems: "center",
              padding: "0 8px", 
              height: "100%", 
            }}
          >
            {params.InputProps.endAdornment}
            <span style={{ marginLeft: "5px" }}> % </span>
          </InputAdornment>
        ),
      }}
    />
  )}
/>

              <div style={{ marginLeft: "30px" }}>
                <FormControl sx={{ width: "309px" }}>
                  <InputLabel id="demo-select-small-label">Status</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={status}
                    disabled={view && !edit}
                    label="Status"
                    onChange={handleChange}
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value={"Active"}>Active</MenuItem>
                    <MenuItem value={"Accepted"}>Accepted</MenuItem>
                    <MenuItem value={"Rejected"}>Rejected</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </td>
            <td style={{ width: "25%" }}></td>
            <td style={{ width: "25%" }}></td>
          </tr>
          <tr>
            <td
              style={{
                display: "flex",
                padding: "10px",
              }}
            >
               <PheonixTextField
                id="outlined-error"
                type="text"
                disabled={view && !edit}
                value={QuotesData["Tax Amount"]}
                placeholder="Tax Amount"
                label="Tax Amount"
                name="Tax Amount"
                variant="outlined"
                size="small"
                error={!!fieldErrors?.["Tax Amount"]}
                helperText={fieldErrors["Tax Amount"]}
                onChange={(e) =>
                  handleInputChange(e.target.name, e.target.value)
                }
                style={{ width: "309px" }}
              />
              <div style={{ marginLeft: "30px" }}>
              <PheonixTextField
                id="outlined-error"
                type="text"
                disabled={view && !edit}
                value={QuotesData["Total Amount"]}
                placeholder="Total Amount"
                label="Total Amount"
                name="Total Amount"
                variant="outlined"
                size="small"
                error={!!fieldErrors?.["Total Amount"]}
                helperText={fieldErrors["Total Amount"]}
                onChange={(e) =>
                  handleInputChange(e.target.name, e.target.value)
                }
                style={{ width: "309px" }}
              />
              </div>
            </td>
            <td style={{ width: "25%" }}></td>
            <td style={{ width: "25%" }}></td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <PheonixTextField
                id="outlined-error"
                type="textarea"
                disabled={view && !edit}
                value={QuotesData["Customer Notes"]}
                placeholder="Customer Notes"
                name="Customer Notes"
                label="Customer Notes"
                variant="outlined"
                size="small"
                error={!!fieldErrors?.["Customer Notes"]}
                helperText={fieldErrors["Customer Notes"]}
                onChange={(e) =>
                  handleInputChange(e.target.name, e.target.value)
                }
                style={{ width: "652px" }}
                multiline={true}
                rows={2}
              />
            </td>
            <td style={{ width: "25%" }}></td>
            <td style={{ width: "25%" }}></td>
            <td style={{ width: "25%" }}></td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <Typography style={{ fontSize: "16px",
                fontFamily: "Roboto",
                color: "#000000",
                fontWeight: 600,
                lineHeight:'24px',
                letterSpacing:'0.15px',
                marginBottom:'5px'}}>{TEXT_MESSAGES.TERMS}</Typography>
              <PheonixTextField
                id="outlined-error"
                type="textarea"
                disabled={view && !edit}
                value={QuotesData["Terms and Conditions"]}
                placeholder="Terms and Conditions"
                name="Terms and Conditions"
                label="Terms and Conditions"
                variant="outlined"
                size="small"
                error={!!fieldErrors?.["Terms and Conditions"]}
                helperText={fieldErrors["Terms and Conditions"]}
                onChange={(e) =>
                  handleInputChange(e.target.name, e.target.value)
                }
                style={{ width: "652px" }}
                multiline={true}
                rows={3}  
              />
            </td>
            <td style={{ width: "25%" }}></td>
            <td style={{ width: "25%" }}></td>
            <td style={{ width: "25%" }}></td>
          </tr>
        </tbody>
      </table>
    </Box>
  );
};
export default QuoteTable;
